
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card, Tag, message, Tooltip, Popover, Form, Input } from 'antd';
import useTable from '../../hooks/table';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useDynamicForm from '../../hooks/dynamic_form';
import CustomFunctions from '../../dependencies/custom/custom-functions';

const CourseAssignmentExtra = (props) => {
    const valuesStore = ValuesStore();
    const extraFiltersAddMeta = 'extraFiltersAddMeta';
    const extraFiltersAdd = useAdd(extraFiltersAddMeta, 'table_name');

    const { filters, filterTypes } = utils.generateTableFilters();
    // const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();

    const [btnPermissions, setBtnPermissions] = useState({});
    // const [sheetInfo, setSheetInfo] = useState({});


    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight', 'topRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_assigned_courses_lecturer_extra`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'course_lecturer_general', fields: ['*'] }, true);

    function resetTable() {
        navigate(0);
    }

    function actionExtraButtons(record) {
        const btns = <div>            
            {btnPermissions?.show_publish_button && <Button onClick={e => changeSheetStatus(record, 'publish', 'single')}><i className='fas fa-check-double  me-2' /> Publish</Button>}
            {btnPermissions?.show_unpublish_button && <Button onClick={e => changeSheetStatus(record, 'unpublish', 'single')}><i className='far fa-times-circle me-2' /> Unpublish </Button>}
        </div>;
        return btns;
    }

    function changeSheetStatus(record, status, type) {
        switch (status) {            
            case "publish": {
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        CustomFunctions.changeSheetStatusExtra(table.selectedRows, 'publish');
                    } else {
                        utils.showNotification(undefined, 'No selection was done');
                    }
                } else {
                    CustomFunctions.changeSheetStatusExtra([record], 'publish');
                }
                break;
            }
            
            case "unpublish": {
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        CustomFunctions.changeSheetStatusExtra(table.selectedRows, 'unpublish');
                    } else {
                        utils.showNotification(undefined, 'No selection was done');
                    }
                } else {
                    CustomFunctions.changeSheetStatusExtra([record], 'unpublish');
                }
                break;
            }
        }
    }



    const columns = ([
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Tooltip title="View students and grades">
                        <Button className='btn-primaryx border-0x' onClick={e => gotoSheet(record)}><i className='fas fa-eye text-primary' /></Button>
                    </Tooltip>                   
                    {btnPermissions?.show_more_actions_on_sheet_button && <Tooltip title="More Actions" placement='bottomRight'>
                        <Popover placement="topLeft" title={'More Actions'} content={actionExtraButtons(record)} trigger="click">
                            <Button className='btn-infox border-0x'><i className='fas fa-external-link-alt text-info' /></Button>
                        </Popover>
                    </Tooltip>}

                </Space>
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Program',
            dataIndex: 'short_name',
            filterSearch: true
        },
        {
            title: 'AcadYr',
            dataIndex: 'acad_year',
            ...table.getColumnSearchProps('acad_year'),
        },

        {
            title: 'Course',
            dataIndex: 'course_id',
            ...table.getColumnSearchProps('course_id'),
            render: (_, record) => {
                return `${record.course_id}(${record.course_name})`
            }
        },
        {
            title: 'Session',
            dataIndex: 'session',
            // render: (_, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./ad_details?advert_id=${record['custom_id']}#page=5`)}><i className='fas fa-user' /></a>
            // },
        },
        {
            title: 'Semester',
            filterSearch: true,
            dataIndex: 'semester_id',
        },
        {
            title: 'Lecturer',
            dataIndex: 'lecturer',
            ...table.getColumnSearchProps('lecturer'),
            render: (_, record) => {
                return `${record.lecturer_id}`
            }
        },

    ]);

    function gotoSheet(record) {
        navigate('../grade_hx', { state: record });
    }

    const colFilters = [
        {
            filter: 'session',
            sql: "SELECT session,alias FROM sessions",
            key: 'alias',
            value: 'session'
        },
        {
            filter: 'semester_id',
            sql: 'SELECT semester,alias FROM semester',
            key: 'alias',
            value: 'semester'
        },
        {
            filter: 'status',
            sql: 'SELECT distinct status FROM scoresheet_summary_extra',
            key: 'status',
            value: 'status'
        },
        {
            filter: 'short_name',
            sql: 'SELECT short_name,alias FROM programs WHERE show_hide = 1',
            key: 'alias',
            value: 'short_name'
        }
    ];


    // async function setTableDataURL() {
    //     //the permissions are arranged from max to min. Max perms have the highest priority
    //     const permList = [
    //         'get_assigned_courses_lecturer_extra',
    //         'get_assigned_courses_general',
    //         'get_assigned_courses_lecturer_general'];
    //     for (let i = 0; i < permList.length; i++) {
    //         const v = permList[i];
    //         const hasPerm = await utils.hasPermission(v, null, valuesStore);
    //         if (hasPerm) {
    //             table.setDataURL(`${Settings.backend}/${v}`);
    //             break;
    //         }
    //     }
    // }

    function showBtnUsingPermissions() {
        if (Object.keys(btnPermissions) <= 0) {
            const permList = [
                'show_publish_button',
                'show_unpublish_button',
                // 'show_goto_sheet_button',             
            ];
            permList.forEach(async v => {
                const perm = await utils.hasPermission(v, null, valuesStore);
                setBtnPermissions(r => ({ ...r, [v]: perm }));//permission as key and true/false as value
            });
            table.rowSelectionDebug();
        }
    }



    function setupExtraFilters() {
        setTimeout(e => {
            valuesStore.setValue(extraFiltersAddMeta, [
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'assigned_by', col_real_name: 'Assign By', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT staff_id,name FROM admin","key":"staff_id","value":"staff_id,name"}',
                    extra_options: '',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                },
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'submitted', col_real_name: 'Submitted', type: 'jsonMultiSelect',
                    options: '{"1":"Yes","0":"No"}',
                    extra_options: ``,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 2
                },
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'mounted_for', col_real_name: 'Category', type: 'csvMultiSelect',
                    options: 'normal,resit,ic',
                    extra_options: ``,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 3
                },
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'status', col_real_name: 'Status', type: 'csvMultiSelect',
                    options: 'submitting,approved,published',
                    extra_options: ``,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 3
                }

            ]);
            extraFiltersAdd.setTblName('course_assignment_filters');
        }, 1000);
    }


    useMemo(() => {

        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        table.setAllowSelection(true);

        console.log('looping')
        // applyFilter();
        showBtnUsingPermissions();

    }, [/*sheetInfo, btnPermissions*/ table.extraFetchParams]);

    function applyFilter() {
        table.rowSelectionDebug();
        table.setSelectedRows([]);
        if (extraFiltersAdd.record?.assigned_by) {
            let values = extraFiltersAdd.record?.assigned_by.split(',').map(v => v);
            filters['assign_by'] = values;
        }
        if (extraFiltersAdd.record?.submitted) {
            let values = extraFiltersAdd.record?.submitted.split(',').map(v => v)
            filters['submitted'] = values;
        }
        if (extraFiltersAdd.record?.mount_type) {
            let values = extraFiltersAdd.record?.mount_type.split(',').map(v => v)
            filters['mount_type'] = values;
        }
        if (extraFiltersAdd.record?.mounted_for) {
            let values = extraFiltersAdd.record?.mounted_for.split(',').map(v => v)
            filters['mounted_for'] = values;
        }
        if (extraFiltersAdd.record?.status) {
            let values = extraFiltersAdd.record?.status.split(',').map(v => v);
            filters['status'] = values;
        }
        table.setTableParams(r => ({ ...r, filters: { ...r.filters, ...filters } }));
        //this is fixing a bug
        if (Object.keys(filters) <= 0) {
            table.setTableParams(r => ({ ...r, filters }));
        }
        // let startDate = dateRange[0]?.$d;
        // let endDate = dateRange[1]?.$d;
        // if (startDate && endDate) {
        //     utils.filterByDate('course_lecturer_general.date_inserted', dateRange, table);
        // }
    }

    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <Card
                    bordered={false}
                    className="criclebox tablespace border-0 mb-24 p-2"
                    title="Course Mgt. Extra"
                    extra={
                        <Space>
                            <Button onClick={e => applyFilter()}><i className='fas fa-filter me-2' />Filter</Button>
                            <Button onClick={e => resetTable()}><i className='fas fa-filter me-2' /> Clear All Filters</Button>
                            {btnPermissions?.show_approve_button && <Button onClick={e => changeSheetStatus(null, 'approve', 'multiple')}><i className='far fa-check-circle me-2' /> Approve All</Button>}
                            {btnPermissions?.show_release_sheet_button && <Button onClick={e => changeSheetStatus(null, 'release', 'multiple')}><i className='fas fa-unlock me-2' /> Release All</Button>}
                            {btnPermissions?.show_publish_button && <Button onClick={e => changeSheetStatus(null, 'publish', 'multiple')}><i className='fas fa-check-double  me-2' /> Publish All</Button>}
                            {btnPermissions?.show_unpublish_button && <Button onClick={e => changeSheetStatus(null, 'unpublish', 'multiple')}><i className='far fa-times-circle me-2' /> Unpublish All </Button>}
                        </Space>
                    }
                >

                    <div className='row'>
                        <div className='col-md-12 mb-2'>
                            <div className='mt-2'>
                                <div className='row row-cols-2'>
                                    {extraFiltersAdd?.form?.map((v, i) => <div key={i}>{v}</div>)}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='table-responsive h-scrolling-wrapper'>
                                {table.tableWithHeaderFooter()}
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
}

export default memo(CourseAssignmentExtra);