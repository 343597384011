
import React, { useState, memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Result, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'
import Settings from '../../dependencies/custom/settings';


const StudentPasswordRecovery = (props) => {
    const location = useLocation();
    const [feedback, setFeedback] = useState();
    let callbackURL = undefined;
    useMemo(() => {
        const qs = location.search.split('&');
        const token = qs[0]?.replace('?', '')?.split('=')[1];
        const callback = qs[1]?.split('=')[1];
        verifyToken(token);
        callbackURL = callback;
    }, []);

    function goToSPortal() {
        if (callbackURL) {
            window.location.href = callbackURL
        }
    }

    async function verifyToken(token) {
        const url = `${Settings.backend}/verify_std_psd_recovery_token`;
        const res = await utils.request('post', url, null, { URLToken: token });
        if (res?.status == 'Ok') {
            setFeedback(<Result
                status="success"
                title="Your password has successfully been recovered. Please go to the student portal and login using your index number as index number and password"
                extra={[
                    <Button onClick={e => goToSPortal()} type="primary" key="console">
                        Go to student Portal
                    </Button>
                ]}
            />);

        } else {
            setFeedback(<Result
                status="warning"
                title={res.msg}
                extra={[
                    <Button onClick={e => goToSPortal()} type="primary" key="console">
                        Go to student Portal
                    </Button>
                ]}
            />);
        }
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <>
            {feedback ?
                feedback :
                <Spin indicator={antIcon} />
            }
        </>
    );
}

export default memo(StudentPasswordRecovery);