
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card, Tag, message, Tooltip, Popover, Form, Input } from 'antd';


import useTable from '../../hooks/table';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useDynamicForm from '../../hooks/dynamic_form';
import CustomFunctions from '../../dependencies/custom/custom-functions';
import AppContainer from '../../components/container';

const CourseAssignment = (props) => {
    const valuesStore = ValuesStore();
    const extraFiltersAddMeta = 'extraFiltersAddMeta';
    // const coursesForTransferFilter = 'coursesForTransferFilter';    
    const add = useAdd('tables_metadata', 'table_name');
    const extraFiltersAdd = useAdd(extraFiltersAddMeta, 'table_name');
    // const coursesForTransferAdd = useAdd(coursesForTransferFilter, 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    const [btnPermissions, setBtnPermissions] = useState({});
    const [sheetInfo, setSheetInfo] = useState({});
    const formData = [
        { name: 'label', isRequired: true, errorMsg: 'Field is required', placeholder: 'Enter column label', inputType: 'text' },
        { name: 'marks', isRequired: true, errorMsg: 'Field is required', placeholder: 'Enter marks', inputType: 'number' }
    ];
    const dynamicForm = useDynamicForm('myForm', formData, { text: <><i className='fas fa-paper-plane me-1' />Submit</>, type: 'primary' });
    const [recalledColumnInfo, setRecalledColumnInfo] = useState({});
    // const [dynaFormChildren, setDynaFormChildren] = useState({
    //     exam: <Space
    //         style={{
    //             display: 'flex',
    //             marginBottom: 8,
    //         }}
    //         align="baseline"
    //     >
    //         <Form.Item
    //             name="examLabel"
    //             label="Label"
    //             rules={[
    //                 {
    //                     required: true,
    //                 }
    //             ]}
    //         >
    //             <Input disabled />
    //         </Form.Item>

    //         <Form.Item
    //             name="examMarks"
    //             label="Marks"
    //             rules={[
    //                 {
    //                     required: true
    //                 }
    //             ]}
    //         >
    //             <Input type='number' /*disabled={closedScheme ? true : false}*/ />
    //         </Form.Item>
    //     </Space>,
    //     edited: ""
    // });

    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight', 'topRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'course_lecturer', fields: ['*'] }, true);

    function resetTable() {
        navigate(0);
    }

    function actionExtraButtons(record) {
        const btns = <div>
            {/* {btnPermissions?.show_transfer_button &&
                <Popover placement="topLeft" title={'Transfer course'} content={courseTransferJSX(record)} trigger="click">
                    <Button><i className='fas fa-paper-plane me-2' /> Transfer </Button>
                </Popover>
            } */}
            {btnPermissions?.show_approve_button && <Button onClick={e => changeSheetStatus(record, 'approve', 'single')}><i className='far fa-check-circle me-2' /> Approve</Button>}
            {btnPermissions?.show_release_sheet_button && <Button onClick={e => changeSheetStatus(record, 'release', 'single')}><i className='fas fa-unlock me-2' /> Release</Button>}
            {btnPermissions?.show_publish_button && <Button onClick={e => changeSheetStatus(record, 'publish', 'single')}><i className='fas fa-check-double  me-2' /> Publish</Button>}
            {btnPermissions?.show_unpublish_button && <Button onClick={e => changeSheetStatus(record, 'unpublish', 'single')}><i className='far fa-times-circle me-2' /> Unpublish </Button>}
        </div>;
        return btns;
    }

    // function courseTransferJSX(record) {
    //     return <div className=''>
    //         {coursesForTransferAdd.form}
    //         <Button onClick={e => transferCourse(record)} className='btn-info border-0'><i className='fas fa-paper-plane me-1' />Transfer</Button>
    //     </div>;
    // }

    // async function transferCourse(record) {
    //     const data = { mounted_course_id: record?.mounted_course_id, ...coursesForTransferAdd.record };
    //     const res = await utils.requestWithReauth('post', `${Settings.backend}/transfer_course`, null, data);
    //     if (res.status == 'Ok') {
    //         utils.showNotification(undefined, 'Operation successful', 'text-success');
    //     } else {
    //         utils.showNotification(undefined, res.msg);
    //     }

    // }

    function changeSheetStatus(record, status, type) {
        switch (status) {
            case "approve": {
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        CustomFunctions.changeSheetStatus(table.selectedRows, 'approve');
                    } else {
                        utils.showNotification(undefined, 'No selection was done');
                    }
                } else {
                    CustomFunctions.changeSheetStatus([record], 'approve');
                }

                break;
            }
            case "publish": {
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        CustomFunctions.changeSheetStatus(table.selectedRows, 'publish');
                    } else {
                        utils.showNotification(undefined, 'No selection was done');
                    }
                } else {
                    CustomFunctions.changeSheetStatus([record], 'publish');
                }
                break;
            }
            case "release": {
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        CustomFunctions.changeSheetStatus(table.selectedRows, 'release');
                    } else {
                        utils.showNotification(undefined, 'No selection was done');
                    }
                } else {
                    CustomFunctions.changeSheetStatus([record], 'release');
                }
                break;
            }
            case "unpublish": {
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        CustomFunctions.changeSheetStatus(table.selectedRows, 'unpublish');
                    } else {
                        utils.showNotification(undefined, 'No selection was done');
                    }
                } else {
                    CustomFunctions.changeSheetStatus([record], 'unpublish');
                }
                break;
            }
        }
    }



    const columns = ([
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    {btnPermissions?.show_add_sheet_column_button && <Tooltip title="Manage Sheet Column">
                        <Button className='btn-secondaryx border-0x' onClick={e => manageSheetColumn(record)}><i className='fas fa-file text-secondary' /></Button>
                    </Tooltip>}
                    {btnPermissions?.show_goto_sheet_button && <Tooltip title="View sheet">
                        <Button className='btn-primaryx border-0x' onClick={e => gotoSheet(record)}><i className='fas fa-eye text-primary' /></Button>
                    </Tooltip>}
                    {/* {btnPermissions?.show_edit_course_assignment_button && <Tooltip title="Edit course assignment">
                        <Button className='btn-success border-0' onClick={e => editRecord(record, 'acad_calendar')}><i className='fas fa-edit' /></Button>
                    </Tooltip>} */}
                    {btnPermissions?.show_del_course_assignment_button && del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'course_lecturer', where: 'id', whereType: 'closed' },
                        <Tooltip placement='bottomLeft' title="Delete course assignment. This action will not work if there are no traces of the assignment">
                            <Button className='btn-dangerx border-0x'>  <i className='fas fa-trash text-danger' /></Button>
                        </Tooltip>
                    )}
                    {btnPermissions?.show_more_actions_on_sheet_button && <Tooltip title="More Actions" placement='bottomRight'>
                        <Popover placement="topLeft" title={'More Actions'} content={actionExtraButtons(record)} trigger="click">
                            <Button className='btn-infox border-0x'><i className='fas fa-external-link-alt text-info' /></Button>
                        </Popover>
                    </Tooltip>}
                </Space>
            },
        },
        {
            title: '',
            dataIndex: 'mounted_for',
            render: (v, r) => {
                if (r.mounted_under) {
                    return <>
                        <Tag color={'blue'} className={`rounded`}>{'normal'}</Tag>
                        {r.status === 'published' ? <i className='fas fa-check-double text-success' /> : r.status === 'approved' ? <i className='far fa-check-circle text-warning' /> : <i className='fas fa-spinner text-danger' />}

                    </>
                } else {
                    return <>
                        <Tag color={v === 'normal' ? 'Green' : 'Red'} className={`rounded`}>{v}</Tag>
                        {r.status === 'published' ? <i className='fas fa-check-double text-success' /> : r.status === 'approved' ? <i className='far fa-check-circle text-warning' /> : <i className='fas fa-spinner text-danger' />}
                    </>
                }
            }
        },
        {
            title: 'Course',
            dataIndex: 'course_code',
            ...table.getColumnSearchProps('course_code'),
            // filterSearch: true,
            render: (_, record) => {
                return `${record.course_code}(${record.course_name})`
            }
        },
        {
            title: 'AcadYr',
            dataIndex: 'acad_year',
            ...table.getColumnSearchProps('acad_year'),
            // filteredValue: table.filteredInfo.acad_year || null,
        },
        {
            title: 'Level',
            filterSearch: true,
            dataIndex: 'mounted_level',
        },
        {
            title: 'Session',
            dataIndex: 'session',
            // render: (_, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./ad_details?advert_id=${record['custom_id']}#page=5`)}><i className='fas fa-user' /></a>
            // },
        },
        {
            title: 'Semester',
            filterSearch: true,
            dataIndex: 'semester_id',
        },
        {
            title: 'Program',
            dataIndex: 'program',
            // ...table.getColumnSearchProps('program'),
            filterSearch: true,
            // render: (_, record) => {
            //     return `${record.course_code}(${record.course_name})`
            // }
        },
        {
            title: 'Lecturer',
            dataIndex: 'lecturer',
            ...table.getColumnSearchProps('lecturer'),
            render: (_, record) => {
                return `${record.lecturer}(${record.lecturer_id})`
            }
        }
    ]);

    function recallColumn(label, marks, record) {
        setRecalledColumnInfo(record);
        const a = setExamsField();
        //prepare the input fields
        dynamicForm.setFormChildren([a,
            <Space
                key={'2'}
                style={{
                    display: 'flex',
                    marginBottom: 8,
                }}
                align="baseline"
            >
                <Form.Item
                    name="label"
                    label="Label"
                    rules={[
                        {
                            required: true,
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="marks"
                    label="Marks"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                >
                    <Input type='number' />
                </Form.Item>
            </Space>
        ]);
        //set the values to the prepared input fields. values get assigned through the name prop of the Form.Item component
        dynamicForm.form.setFieldsValue({
            label: label,
            marks: marks
        });
    }


    function setExamsField() {
        const settings = valuesStore.getArrayObjectsValue("settings", "prop", "marksAllocationPolicy");
        const val = JSON.parse(settings?.value || "{}");
        const jsx = <Space
            key={'1'}
            style={{
                display: 'flex',
                marginBottom: 8,
            }}
            align="baseline"
        >
            <Form.Item
                name="examLabel"
                label="Label"
                rules={[
                    {
                        required: true,
                    }
                ]}
            >
                <Input disabled />
            </Form.Item>

            <Form.Item
                name="examMarks"
                label="Marks"
                rules={[
                    {
                        required: true
                    }
                ]}
            >
                <Input type='number' />
            </Form.Item>
        </Space>;
        //prepare the input fields
        dynamicForm.setFormChildren(jsx);
        //set the values to the prepared input fields. values get assigned throught the name prop of the Form.Item component
        dynamicForm.form.setFieldsValue({
            examLabel: 'Exam',
            examMarks: val?.external
        });
        return jsx;
    }

    function delCallback(status) {
        if (status) {
            dynamicForm.setShowModal(false);
        }
    }

    async function manageSheetColumn(record) {
        setSheetInfo(record);
        dynamicForm.setFormChildren(undefined);
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_sheet_columns`, null, { courseLecturerID: record.custom_id });
        const data = res.results?.map(v => {
            return [v.label, v.marks_allotted,
            <Space>
                {v.label !== 'Exam' && <Tooltip title="Edit sheet column">
                    <Button onClick={e => recallColumn(v.label, v.marks_allotted, v)}><i className='fas fa-edit text-success' /></Button>
                </Tooltip>}
                {/*v.label !== 'Exam' && */del.confirm(
                    `${Settings.backend}/delete`,
                    v,
                    'Are you sure to delete this item',
                    { tableName: 'scheme', where: 'id', whereType: 'closed' },
                    <Tooltip title="Delete sheet column" placement='bottom'>
                        <Button><i className='fas fa-trash text-danger' /></Button>
                    </Tooltip>
                    , undefined, undefined, undefined, delCallback)}
            </Space>
            ];
        });

        const details = utils.getTable(
            [
                <label>Label</label>,
                <label>Marks</label>,
                <label>Actions</label>
            ],
            data, 'w-100 table table-sm table-striped', '', '', '', 3);
        dynamicForm.setChildrenBottom(details);
        dynamicForm.setShowModal(true);
        dynamicForm.setModalTitle(`New Sheet Column(s)/${record.course_code}-${record.session}-${record.acad_year}-${record.mounted_for}`);
    }



    function gotoSheet(record) {
        // localStorage.setItem('sheetQueryParams', JSON.stringify({ lecturerID: record?.lecturer_id, courseLecturerID: record?.custom_id, consolidatedIDs: record?.consolidatedIDs }));
        // valuesStore.setValue('sheetQueryParams', { lecturerID: record?.lecturer_id, courseLecturerID: record?.custom_id, consolidatedIDs: record?.consolidatedIDs });
        navigate('../mark_entry', { state: { lecturerID: record?.lecturer_id, courseLecturerID: record?.custom_id, consolidatedIDs: record?.consolidatedIDs } });
    }

    function assignCourse(tableName = 'course_lecturer', record) {
        // add.setRecord({ assign_by: 'K3050', submitted: '0' });
        add.setSqlPlaceHolders({ 'placeholder1': 'K3050' });
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    const colFilters = [
        {
            filter: 'session',
            sql: "SELECT session,alias FROM sessions",
            key: 'alias',
            value: 'session'
        },
        {
            filter: 'mounted_level',
            sql: "SELECT level FROM level",
            key: 'level',
            value: 'level'
        },
        // {
        //     filter: 'course_code',
        //     sql: "SELECT course_name,course_code FROM courses",
        //     key: 'course_code',
        //     value: 'course_name'
        // },
        {
            filter: 'program',
            sql: "SELECT name,alias FROM programs WHERE show_hide =1 ORDER BY name ",
            key: 'alias',
            value: 'name'
        },
        {
            filter: 'semester_id',
            sql: 'SELECT semester,alias FROM semester',
            key: 'alias',
            value: 'semester'
        },
        {
            filter: 'mount_type',
            sql: 'SELECT distinct mount_type FROM course_lecturer',
            key: 'mount_type',
            value: 'mount_type'
        }
    ];


    async function addOnOk() {
        let res = await utils.requestWithReauth('post', `${Settings.backend}/save_assign_course`, null, add.record);
        if (res.status === 'Ok') {
            add.reset();
            utils.showNotification(undefined, 'Operation successful', 'text-success');
        } else {
            utils.showNotification(undefined, res.msg);
        }
    }


    async function setTableDataURL() {
        //the permissions are arranged from max to min. Max perms have the highest priority
        const permList = [
            'get_assigned_courses_root',
            'get_assigned_courses',
            'get_assigned_courses_lecturer'];
        for (let i = 0; i < permList.length; i++) {
            const v = permList[i];
            const hasPerm = await utils.hasPermission(v, null, valuesStore);
            if (hasPerm) {
                table.setDataURL(`${Settings.backend}/${v}`);
                break;
            }
        }
    }

    function showBtnUsingPermissions() {
        if (Object.keys(btnPermissions) <= 0) {
            const permList = [
                'show_approve_button',
                'show_publish_button',
                'show_unpublish_button',
                'show_release_sheet_button',
                'show_assign_course_button',
                'show_edit_course_assignment_button',
                'show_del_course_assignment_button',
                'show_add_sheet_column_button',
                'show_goto_sheet_button',
                'show_more_actions_on_sheet_button',
                'show_grade_request_button',
                'show_transfer_button'
            ];
            permList.forEach(async v => {
                const perm = await utils.hasPermission(v, null, valuesStore);
                setBtnPermissions(r => ({ ...r, [v]: perm }));//permission as key and true/false as value
            });
            table.rowSelectionDebug();
        }
    }

    // function coursesForTransfer() {
    //     setTimeout(e => {
    //         valuesStore.setValue(coursesForTransferFilter, [
    //             {
    //                 id: '', table_name: 'courses_for_transfer',
    //                 column_name: 'dept', col_real_name: 'Department', type: 'sqlSelect',
    //                 options: '{"sql":"SELECT name,alias FROM organizational_type WHERE type in (4,5)","key":"alias","value":"name"}',
    //                 extra_options: '',
    //                 backend_visible: 1, front_visible: 0,
    //                 editable: 1, validator: '', icon: '', rank: 1
    //             }]);
    //         coursesForTransferAdd.setTblName('courses_for_transfer');
    //     }, 1000);
    // }

    function setupExtraFilters() {
        setTimeout(e => {
            valuesStore.setValue(extraFiltersAddMeta, [
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'assigned_by', col_real_name: 'Assign By', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT staff_id,name FROM admin","key":"staff_id","value":"staff_id,name"}',
                    extra_options: '',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                },
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'submitted', col_real_name: 'Submitted', type: 'jsonMultiSelect',
                    options: '{"1":"Yes","0":"No"}',
                    extra_options: ``,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 2
                },
                // {
                //     id: '', table_name: 'course_assignment_filters',
                //     column_name: 'mount_type', col_real_name: 'Type', type: 'csvMultiSelect',
                //     options: 'core,elective',
                //     extra_options: ``,
                //     backend_visible: 1, front_visible: 0,
                //     editable: 1, validator: '', icon: '', rank: 3
                // },
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'mounted_for', col_real_name: 'Category', type: 'csvMultiSelect',
                    options: 'normal,resit,ic',
                    extra_options: ``,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 3
                },
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'status', col_real_name: 'Status', type: 'csvMultiSelect',
                    options: 'submitting,approved,published',
                    extra_options: ``,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 3
                }

            ]);
            extraFiltersAdd.setTblName('course_assignment_filters');
        }, 1000);
    }



    useMemo(() => {
        if (!dynamicForm.formChildren) {
            setExamsField();
        }
        setTableDataURL();
        setupExtraFilters();
        // coursesForTransfer();
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        table.setAllowSelection(true);

        console.log('looping')
        applyFilter();
        showBtnUsingPermissions();

        dynamicForm.setFormSubmit({
            async onFormSubmit(values) {
                const courseLecturerID = sheetInfo?.custom_id;
                const colID = recalledColumnInfo?.id;
                const data = { courseLecturerID, colID, examEntry: { marks: values.examMarks, label: values.examLabel }, edited: { marks: values.marks, label: values.label }, newEntry: values.myForm };

                const settings = valuesStore.getArrayObjectsValue("settings", "prop", "marksAllocationPolicy");
                const val = JSON.parse(settings?.value || "{}");
                const closedScheme = val?.closed;

                const url = closedScheme ? `${Settings.backend}/save_scheme` : `${Settings.backend}/save_opened_scheme`;
                let res = await utils.requestWithReauth('post', url, null, data);
                if (res.status === 'Ok') {
                    utils.showNotification(undefined, 'Column(s) have been created successfully', 'text-success');
                    dynamicForm.setShowModal(false);
                } else {
                    utils.showNotification(undefined, res.msg);
                }
            }
        });
    }, [sheetInfo, recalledColumnInfo, add.saveCompleted, del.saveCompleted, btnPermissions/*,coursesForTransferAdd.form*/]);

    function applyFilter() {
        table.rowSelectionDebug();
        table.setSelectedRows([]);
        if (extraFiltersAdd.record?.assigned_by) {
            let values = extraFiltersAdd.record?.assigned_by.split(',').map(v => v);
            filters['assign_by'] = values;
        }
        if (extraFiltersAdd.record?.submitted) {
            let values = extraFiltersAdd.record?.submitted.split(',').map(v => v)
            filters['submitted'] = values;
        }
        if (extraFiltersAdd.record?.mount_type) {
            let values = extraFiltersAdd.record?.mount_type.split(',').map(v => v)
            filters['mount_type'] = values;
        }
        if (extraFiltersAdd.record?.mounted_for) {
            let values = extraFiltersAdd.record?.mounted_for.split(',').map(v => v)
            filters['mounted_for'] = values;
        }
        if (extraFiltersAdd.record?.status) {
            let values = extraFiltersAdd.record?.status.split(',').map(v => v);
            filters['status'] = values;
        }
        table.setTableParams(r => ({ ...r, filters: { ...r.filters, ...filters } }));
        //this is fixing a bug
        if (Object.keys(filters) <= 0) {
            table.setTableParams(r => ({ ...r, filters }));
        }
        let startDate = dateRange[0]?.$d;
        let endDate = dateRange[1]?.$d;
        if (startDate && endDate) {
            utils.filterByDate('course_lecturer.date_inserted', dateRange, table);
        }
    }

    return (
        <>
            <AppContainer
                extra={
                    <>
                        {btnPermissions?.show_assign_course_button && <Button className='btn-success border-0' onClick={e => assignCourse()}><i className='fas fa-user-plus me-2' /> Assign Course</Button>}
                        <Button className='btn-primary' onClick={e => applyFilter()}><i className='fas fa-filter me-2' />Filter</Button>
                        <Button className='btn-primary' onClick={e => resetTable()}><i className='fas fa-filter me-2' /> Clear All Filters</Button>
                        {btnPermissions?.show_approve_button && <Button className='btn-primary' onClick={e => changeSheetStatus(null, 'approve', 'multiple')}><i className='far fa-check-circle me-2' /> Approve All</Button>}
                        {btnPermissions?.show_release_sheet_button && <Button className='btn-primary' onClick={e => changeSheetStatus(null, 'release', 'multiple')}><i className='fas fa-unlock me-2' /> Release All</Button>}
                        {btnPermissions?.show_publish_button && <Button className='btn-primary' onClick={e => changeSheetStatus(null, 'publish', 'multiple')}><i className='fas fa-check-double  me-2' /> Publish All</Button>}
                        {btnPermissions?.show_unpublish_button && <Button className='btn-primary' onClick={e => changeSheetStatus(null, 'unpublish', 'multiple')}><i className='far fa-times-circle me-2' /> Unpublish All </Button>}
                    </>
                }
                title={'Course Mgt.'}
                children={
                    <div className='row'>
                        <div className='col-md-12 mb-2'>
                            <div className='mt-2'>
                                <div className='row row-cols-2'>
                                    {extraFiltersAdd?.form?.map((v, i) => <div key={i}>{v}</div>)}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='table-responsive h-scrolling-wrapper'>
                                {table.tableWithHeaderFooter()}
                            </div>
                        </div>
                    </div>
                }
                table={table}
            />

            {add.addModal('Assign Course', addOnOk, undefined, undefined, 800)}
            {dynamicForm.formModal(null, null)}
        </>
    );
}

export default memo(CourseAssignment);