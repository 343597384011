import { Watermark } from 'antd';

const Landing = ({ homepage }) => {

    return (
        <>
            <Watermark content={['AISS', 'AAMUSTED INFO. SUPPORT SYS.']}>
                <div                    
                    style={{
                        height: 600,
                    }}
                />
            </Watermark>
        </>

    )
}

export default Landing;

