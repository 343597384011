
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Tag, Card, Drawer, Tooltip, Popover } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import useExcel from '../../hooks/excel';
import Settings from '../../dependencies/custom/settings';
import { confirmAlert } from 'react-confirm-alert';
import CustomFunctions from '../../dependencies/custom/custom-functions';
import useDrawer from '../../hooks/drawer';
import Utilities from '../../dependencies/custom/react-utilities';
import AppContainer from '../../components/container';

const MountedCoursesGeneral = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const remountAddMeta = 'remountAddMeta';
    const courses_reg_students_acadyr = 'courses_reg_students_acadyr';
    const remountAdd = useAdd(remountAddMeta, 'table_name');
    const coursesRegStudentsAcadyr = useAdd(courses_reg_students_acadyr, 'table_name');
    const addAssignedCourse = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const keyOverrides = { level: 'mounted_level', alias: 'org_type' };
    const [rerender, setRerender] = useState(false);
    const [btnPermissions, setBtnPermissions] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(false);
    const excel = useExcel();
    const [courseDetails, setCourseDetails] = useState({});
    const [courseMountModalTitle, setCourseMountModalTitle] = useState('Mount Course(s)');
    // const [studentViewLoading, setStudentViewLoading] = useState(false);
    const studentDetailsDrawer = useDrawer();
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_mounted_courses_general`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'mounted_courses_general', fields: ['*'] });


    const studentListTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 25,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        undefined,
        undefined,
        'id',
        {},
        {}, false);

    const stdListCols = ([
        {
            title: 'Registered',
            dataIndex: 'hasRegistered',
            filters: [
                {
                    text: 'REGISTERED',
                    value: 'Yes'
                },
                {
                    text: 'NOT REGISTERED',
                    value: 'No'
                }
            ],
            onFilter: (v, record) => {
                return record.hasRegistered === v;
            },
            render: (value, record) => {
                return <Tag className='rounded' color={value == 'Yes' ? 'Green' : 'Red'}>
                    {value == 'Yes' ? 'REG' : 'UNR'}
                </Tag>
            },
        },
        {
            title: 'Index No.',
            dataIndex: 'index_no',
            ...table.getColumnSearchProps('index_no'),
        },
        {
            title: 'First name',
            dataIndex: 'fname',
            ...table.getColumnSearchProps('fname'),
        },
        {
            title: 'Last name',
            dataIndex: 'lname',
            ...table.getColumnSearchProps('lname'),
        },
        {
            title: 'Middle name',
            dataIndex: 'mname',
            ...table.getColumnSearchProps('mname'),
        },
        {
            title: 'Gender',
            dataIndex: 'sex',
            filters: [
                {
                    text: 'Male',
                    value: 'Male'
                },
                {
                    text: 'Female',
                    value: 'Female'
                }
            ],
            onFilter: (v, record) => {
                return record.sex === v;
            },
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-primary border-0' onClick={e => CustomFunctions.viewStudentBasicData(record, studentDetailsDrawer, valuesStore)}><i className='fas fa-eye' /></Button>
                </Space>
            },
        },
    ]);



    const columns = ([
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    {/* {btnPermissions?.get_mounted_course_registered_students_general && <Tooltip title='View student list'>
                        <Button className='btn-primary border-0' onClick={e => viewStudents(record)}><i className='fas fa-eye' /></Button>
                    </Tooltip>} */}

                    {btnPermissions?.get_mounted_course_registered_students_general && <Tooltip title='View student list'>
                        <Popover placement="topLeft" title={'View student list'} content={courseRegAcadYrJSX(record)} trigger="click">
                            <Button className='btn-primaryx border-0x'><i className='fas fa-eye text-primary' /></Button>
                        </Popover>
                    </Tooltip>}

                    {btnPermissions?.save_mounted_course_general && <Tooltip title='Assign course'>
                        <Button className='btn-infox border-0x' onClick={e => assignCourse(record)}><i className='fas fa-user-plus text-info' /></Button>
                    </Tooltip>}
                    {btnPermissions?.edit_mounted_courses_general && <Tooltip title='Edit mounted course'>
                        <Button className='btn-successx border-0x' onClick={e => editRecord(record, 'mounted_courses_general')}><i className='fas fa-edit text-success' /></Button>
                    </Tooltip>}
                    {btnPermissions?.delete_mounted_courses_general && <Tooltip title="Delete (Normal mode)" placement='bottom'>{del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'mounted_courses_general', where: 'id', whereType: 'closed' },
                        <Button className='btn-dangerx border-0x'>  <i className='fas fa-trash text-danger' /></Button>
                    )}</Tooltip>}

                    {btnPermissions?.delete_mounted_course_elevated_general && <Tooltip title="Delete (Elevated mode)" placement='bottom'>{del.confirm(
                        `${Settings.backend}/delete_mounted_course_elevated_general`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'mounted_courses_general', where: 'id', whereType: 'closed' },
                        <Button className='btn-dangerx border-0x'>  <i className='fas fa-trash text-secondary' /></Button>
                    )}</Tooltip>}

                </Space>
            },
        },
        {
            title: 'Tag',
            dataIndex: 'mounted_for',
            filters: [
                {
                    text: 'Normal',
                    value: 'normal'
                },
                {
                    text: 'Resit',
                    value: 'resit'
                },
                {
                    text: 'IC',
                    value: 'ic'
                }
            ],
            render: (v, r) => {
                if (r.mounted_under) {
                    return <Tag color={'blue'} className={`rounded`}>{r.mounted_under}</Tag>
                } else {
                    return <Tag color={v === 'normal' ? 'Green' : 'Red'} className={`rounded`}>{v}</Tag>
                }
            }
        },
        {
            title: 'Ccode',
            dataIndex: 'course_code',
            // filterSearch: true
            ...table.getColumnSearchProps('course_code'),
        },
        {
            title: 'Cname',
            dataIndex: 'course_name',
            ...table.getColumnSearchProps('course_name'),
        },
        {
            title: 'Lvl',
            dataIndex: 'level',
            filterSearch: true
        },
        {
            title: 'Session',
            dataIndex: 'session',
            filterSearch: true
        },
        {
            title: 'Sem',
            dataIndex: 'semester_id',
            filterSearch: true
        },
        {
            title: 'Org. Type',
            dataIndex: 'name',
            filterSearch: true
        },
    ]);



    function courseRegAcadYrJSX(record) {
        return <div className=''>
            {coursesRegStudentsAcadyr.form}
            <Button onClick={e => viewStudents(record)} className='btn-primary border-0'><i className='fas fa-paper-plane me-1' />Query</Button>
        </div>;
    }

    async function viewStudents(record) {
        setCourseDetails(record);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_mounted_course_registered_students_general`, null, { custom_id: record.custom_id, acad_year: coursesRegStudentsAcadyr?.record?.acad_year });
        if (res.status == 'Ok') {
            const { list } = res.result;
            const final = list?.map(v => {
                return { ...v, hasRegistered: 'Yes' };
            });
            studentListTable.setColumns(stdListCols);
            studentListTable.setData(final);
            setDrawerOpen(true);
        } else {
            Utilities.showNotification('Attention', res.msg);
        }
    }

    function showBtnUsingPermissions() {
        if (Object.keys(btnPermissions) <= 0) {
            const permList = [
                { perm: 'get_mounted_course_registered_students_general', table: '' },
                { perm: 'save_mounted_course_general', table: '' },
                { perm: 'edit_mounted_courses_general', table: 'mounted_courses_general' },
                { perm: 'delete_mounted_courses_general', table: 'mounted_courses_general' },
                { perm: 'delete_mounted_course_elevated_general', table: '' },
                { perm: 'delete_selected_mounted_course_general', table: '' }
            ];
            permList.forEach(async v => {
                const perm = await utils.hasPermission(v.perm, v.table, valuesStore);
                setBtnPermissions(r => ({ ...r, [v.perm]: perm }));//permission as key and true/false as value
            });
            table.rowSelectionDebug();
        }
    }

    function assignCourse(record, tableName = 'course_lecturer_general') {
        addAssignedCourse.setRecord({ /*assign_by: 'K3050',submitted: '0',*/ mounted_course_id: record['custom_id'] });
        // addAssignedCourse.setSqlPlaceHolders({ 'placeholder1': 'K3050' });
        addAssignedCourse.setTblName(tableName);
        addAssignedCourse.setShowModal(true);
        addAssignedCourse.setSaveCompleted(false);
    }

    function editRecord(record, tableName) {
        // utils.renameKeys(record, keyOverrides);
        const storeKey = 'editableRecord';
        // console.log(record)
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function mountCourse(tableName = 'mounted_courses_general') {
        // add.setRecord({ mounted_by: adminId });
        // add.setSqlPlaceHolders({ 'placeholder1': adminId });
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }


    // function mountSubcourse(type, tableName = 'SUBCOURSES') {
    //     // add.setRecord({ mounted_by: adminId });
    //     // add.setSqlPlaceHolders({ 'course_parent': adminId });
    //     setCourseMountModalTitle(`Mount ${type}`);
    //     setCourseMountingType(type);
    //     add.setTblName(tableName);
    //     add.setShowModal(true);
    //     add.setSaveCompleted(false);
    // }



    async function editOnOk() {
        let res = await edit.save(undefined,
            `${Settings.backend}/edit`,
            'mounted_courses_general',
            { tbl: 'mounted_courses_general', where: 'id', whereType: 'closed' },
        );
    }


    async function addOnOk() {
        add.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/save_mounted_course_general`, null, add.record);
        if (res.status === 'Ok') {
            add.reset();
            utils.showNotification(undefined, 'Operation successful', 'text-success');
        } else {
            utils.showNotification(undefined, res.msg);
        }
        add.setLoading(false);
    }



    async function deleteAll() {
        const records = table.selectedRows;
        if (!records.length) {
            utils.showNotification(undefined, 'No courses have been selected');
            return;
        }
        let res = await utils.requestWithReauth('post', `${Settings.backend}/delete_selected_mounted_course_general`, null, { data: records });
        if (res.status === 'Ok') {
            utils.showNotification(undefined, 'Operation successful', 'text-success');
            setRerender(!rerender);
        } else {
            utils.showNotification(undefined, res.msg);
        }
    }

    function deleteSelected() {
        confirmAlert({
            title: 'Confim deletion of mounted courses',
            message: 'Action cannot be undone Click on Yes to continue. Click on No stop',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => { await deleteAll() }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    async function addAssigned() {
        let res = await utils.requestWithReauth('post', `${Settings.backend}/save_assign_course_general`, null, addAssignedCourse.record);
        if (res.status === 'Ok') {
            addAssignedCourse.reset();
            utils.showNotification(undefined, 'Operation successful', 'text-success');
        } else {
            utils.showNotification(undefined, res.msg);
        }
    }

    const colFilters = [
        {
            filter: 'session',
            sql: "SELECT session,alias FROM sessions",
            key: 'alias',
            value: 'session'
        },
        {
            filter: 'level',
            sql: "SELECT level FROM level",
            key: 'level',
            value: 'level'
        },
        {
            filter: 'name',
            sql: "SELECT name,alias FROM organizational_type",
            key: 'alias',
            value: 'name'
        },
        {
            filter: 'semester_id',
            sql: 'SELECT semester,alias FROM semester',
            key: 'alias',
            value: 'semester'
        }
    ];

    function downloadList() {
        let data = [];
        let di = [];
        const cols = stdListCols.map(v => {
            di.push(v.dataIndex);
            return v.title;
        });
        studentListTable.data?.forEach(v => {
            let obj = {};
            di.forEach(vv => {
                obj[vv] = v[vv];
            });
            data.push(obj);
        });
        excel.exportXLSX(cols, data, 'students_list', `student_list_${new Date().getDate()}.xlsx`);
    }

    function mountedCoursesRegisteredStudentAcadYear() {
        setTimeout(e => {
            valuesStore.setValue(courses_reg_students_acadyr, [
                {
                    id: '', table_name: courses_reg_students_acadyr,
                    column_name: 'acad_year', col_real_name: 'Acad. Year', type: 'sqlSelect',
                    options: '{"sql":"SELECT acadyr FROM acad_year","key":"acadyr","value":"acadyr"}',
                    backend_visible: 1, editable: 1, rank: 1
                }]);
            coursesRegStudentsAcadyr.setTblName(courses_reg_students_acadyr);
        }, 1000);
    }

    useMemo(() => {
        mountedCoursesRegisteredStudentAcadYear();
        table.setColumns(columns);
        table.setAllowSelection(true);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping');
        // table.fetchData();
        showBtnUsingPermissions();

    }, [add.saveCompleted, remountAdd.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams, rerender, btnPermissions, coursesRegStudentsAcadyr.form]);


    return (
        <>
            <AppContainer
                title={"General Mounted Courses"}
                extra={
                    <>
                        {
                            btnPermissions?.save_mounted_course_general && <Tooltip title='Mount courses'>
                                <Button className='btn-primary border-0' onClick={e => mountCourse()}><i className='fas fa-plus me-2' /> Mount Course</Button>
                            </Tooltip>
                        }
                        {
                            btnPermissions?.delete_selected_mounted_course_general && <Tooltip title='Deleted selected mounted courses'>
                                <Button className='btn-danger border-0' onClick={e => deleteSelected()}><i className='fas fa-trash me-2' /> Delete All</Button>
                            </Tooltip>
                        }
                    </>
                }
                children={
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='table-responsive h-scrolling-wrapper'>
                                {table.tableWithHeaderFooter()}
                            </div>
                        </div>
                    </div>
                }
                table={table}
            />

            {edit.editModal('Edit Item', editOnOk)}
            {add.addModal(courseMountModalTitle, addOnOk, undefined, undefined, 800)}
            {addAssignedCourse.addModal('Assign Course', addAssigned, undefined, undefined, 800)}

            <Drawer
                title="Students List"
                width={800}
                onClose={e => setDrawerOpen(false)}
                open={drawerOpen}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                zIndex={1020}
                extra={
                    <Space>
                        <Button type='primary' onClick={e => downloadList()} icon={<i className='fas fa-download me-1' />}>Download</Button>
                    </Space>
                }
            >
                <div className='d-flex flex-column'>
                    <label className='h6'>Program: {courseDetails.name}</label>
                    <label className='h6'>Session: {courseDetails.session}</label>
                    <label className='h6'>Course Name: {courseDetails.course_name}</label>
                    <label className='h6'>Course Code: {courseDetails.course_code}</label>
                    <label className='h6'>Academic Year: {courseDetails.acad_year}</label>
                </div>
                {studentListTable.table}
                {studentDetailsDrawer.drawerJSX(1021)}

            </Drawer>
        </>
    );
}

export default memo(MountedCoursesGeneral);