
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';


const ScoreSheet = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['topRight', 'bottomRight']
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'acad_calendar', fields: ['*'] });


    const columns = ([
        {
            title: 'Alias',
            dataIndex: 'alias',
            ...table.getColumnSearchProps('alias'),
        },
        {
            title: 'Year',
            dataIndex: 'acadyr',
            ...table.getColumnSearchProps('acadyr'),
            // render: (v, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./users?email=${record['owner']}#page=1`)}>{utils.truncateText(v, 8)}</a>
            // }
        },
        {
            title: 'Semester',
            dataIndex: 'semester',
            ...table.getColumnSearchProps('semester'),
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            ...table.getColumnSearchProps('start_date'),
            render: (v) => `${utils.formatDate(v)}`
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            ...table.getColumnSearchProps('end_date'),
            render: (v) => `${utils.formatDate(v)}`
        },       
        {
            title: 'Active',
            dataIndex: 'is_active',
            filters: [
                {
                    text: 'Yes',
                    value: '1'
                },
                {
                    text: 'No',
                    value: '0'
                }
            ],
            render: (v) => `${v ? 'Yes' : 'No'}`
        },       
        // {
        //     title: 'Dets.',
        //     key: 'details',
        //     render: (_, record) => {
        //         return <a className='blue-text' onClick={e => navigate(`./ad_details?advert_id=${record['custom_id']}#page=5`)}><i className='fas fa-user' /></a>
        //     },
        // },        
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-success border-0' onClick={e => editRecord(record, 'acad_calendar')}><i className='fas fa-edit' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'acad_calendar', where: 'id', whereType: 'closed' },
                        <Button className='btn-danger border-0'>  <i className='fas fa-trash ' /></Button>
                    )}
                </Space>
            },
        },
    ]);

   

    function editRecord(record, tableName) {
        utils.renameKeys(record, keyOverrides);
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'advert') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    async function editOnOk() {
        let res = await edit.save(undefined,
            `${Settings.backend}/edit`,
            'advert',
            { tbl: 'advert', where: 'id', whereType: 'closed' },
        );
    }

    async function addOnOk() {
        let res = await add.save(`${Settings.backend}/add`, { tbl: 'advert' })
    }


    useMemo(() => {
        table.setColumns(columns);        
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <Space>
                            <Button className='btn-primary border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Item</Button>
                            <DatePicker.RangePicker onChange={v => setDateRange(v)} value={dateRange} />
                            <Button className='btn-success border-0' onClick={e => utils.filterByDate('date_inserted', dateRange, table)}><i className='fas fa-filter me-2' />Filter</Button>
                        </Space>
                    </div>
                    <div className='col-md-12'>
                        {table.table}
                    </div>
                </div>
            </div>
            {edit.editModal('Edit Item', editOnOk)}
            {add.addModal('Add New Item', addOnOk)}
        </>
    );
}

export default memo(ScoreSheet);