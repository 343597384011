
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import {useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card } from 'antd';

import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import AppContainer from '../../components/container';

const Level = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight','topRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'level', fields: ['*'] });


    const columns = ([
        {
            title: 'Level',
            dataIndex: 'level',
            filterSearch: true,
            // ...table.getColumnSearchProps('level'),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-successx border-0x' onClick={e => editRecord(record, 'level')}><i className='fas fa-edit text-success' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'level', where: 'id', whereType: 'closed' },
                        <Button className='btn-dangerx border-0x'>  <i className='fas fa-trash text-danger' /></Button>
                    )}
                </Space>
            },
        },
    ]);

    const colFilters = [
        {
            filter: 'level',
            sql: "SELECT id,level FROM level ",
            key: 'id',
            value: 'level'
        }
    ];

    function editRecord(record, tableName) {
        // utils.renameKeys(record, keyOverrides);
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'level') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    async function editOnOk() {
        let res = await edit.save(undefined,
            `${Settings.backend}/edit`,
            'level',
            { tbl: 'level', where: 'id', whereType: 'closed' },
        );
    }

    async function addOnOk() {
        // let res = await add.save(`${Settings.backend}/add`, { tbl: 'level' })
        await add.save(undefined, { tbl: 'level' });
    }


    useMemo(() => {
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);


    return (
        <>
            <AppContainer
                title={"Levels"}
                extra={<Button className='btn-primary border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Item</Button>}
                children={
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='table-responsive h-scrolling-wrapper'>
                                {table.table}
                            </div>
                        </div>
                    </div>
                }
                table={table}
            />           
            {edit.editModal('Edit Item', editOnOk)}
            {add.addModal('Add New Item', addOnOk)}
        </>
    );
}

export default memo(Level);