
import React, { useState, memo, useMemo } from 'react';
import ValuesStore from '../../store/values-store';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card } from 'antd';
import useAdd from '../../hooks/add';

import useTable from '../../hooks/table';
import Settings from '../../dependencies/custom/settings';

import { useLocation, useNavigate } from 'react-router-dom';
import AppContainer from '../../components/container';
const Evaluation = (props) => {
    const valuesStore = ValuesStore();
    const extraFiltersAddMeta = 'extraFiltersAddMeta';
    const add = useAdd('tables_metadata', 'table_name');
    const extraFiltersAdd = useAdd(extraFiltersAddMeta, 'table_name');
    const lookup = { "Strongly Disagree": 1, "Disagree": 2, "Agree": 3, "Strongly Agree": 4 };
    const { filters, filterTypes } = utils.generateTableFilters();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [grandResult, setGrandResult] = useState(0);
    const [categorySummary, setCategorySummary] = useState({});
    const [grandRemark, setGrandRemark] = useState({});
    const designation = [
        { from: 3.50, to: 4.00, remark: 'Excellent' },
        { from: 3.00, to: 3.49, remark: 'Very Good' },
        { from: 2.50, to: 2.99, remark: 'Good' },
        { from: 2.00, to: 2.49, remark: 'Satisfactory' },
        { from: 1.50, to: 1.99, remark: 'Poor' },
        { from: 0.00, to: 1.49, remark: 'Very Poor' }
    ];



    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 100,
                position: ['bottomRight', ''],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        'post',
        'result',
        'totalCount',
        'id',
        {},
        { table: 'admin', fields: ['*'] });

    const columns = ([
        {
            title: 'Item',
            dataIndex: 'question',
            ...table.getColumnSearchProps('question'),
        },
        {
            title: '1',
            dataIndex: 'Strongly Disagree',
        },
        {
            title: '2',
            dataIndex: 'Disagree',
        },
        {
            title: '3',
            dataIndex: 'Agree',
        },
        {
            title: '4',
            dataIndex: 'Strongly Agree',
        },
        {
            title: 'TOTAL',
            dataIndex: 'total',
        },
        {
            title: 'AVERAGE',
            dataIndex: 'average',
        }
    ]);


    const categoryTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 100,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: '', fields: [] });

    const categoryColumns = ([
        {
            title: 'CATEGORY',
            dataIndex: 'description',
        },
        {
            title: 'VALUE',
            dataIndex: 'value',
        },
        {
            title: 'PERFORMANCE',
            dataIndex: 'performance',
        },
        {
            title: 'INCLUDED IN GRAND',
            dataIndex: 'includedInGrand',
        }]
    );


    async function fetchEvaluation() {
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_evaluation_answers`, null, { mounted_courses: [state?.mounted_course_id] });
        let tableData = [];
        let finalRes = {};
        if (res.status == 'Ok') {
            const data = res?.result;
            const initValues = { "Strongly Disagree": 0, "Disagree": 0, "Agree": 0, "Strongly Agree": 0 };
            for (let course in data) {
                const questions = data[course];
                finalRes[course] = [];
                for (let question in questions) {
                    let Efw = 0;
                    let Ef = 0;
                    const selectedAnswer = questions[question];//an array of answers for the agree,disagree etc                    
                    let subObject = { id: `${course}_${question}`, question_id: question, question: selectedAnswer[0]?.col_real_name, group_name: selectedAnswer[0]?.group_name, total: 0, average: 0, ...initValues };
                    for (let ans of selectedAnswer) {
                        const count = parseInt(ans.counter);
                        subObject[ans?.answer] = count;
                        Efw += (lookup[ans?.answer] * count) || 0;
                        Ef += count;
                        subObject['total'] += count;

                    }
                    subObject['average'] = (Efw / Ef)?.toFixed(2) || 0;
                    finalRes[course]?.push(subObject);
                }
            }
        }

        addRows(finalRes);
        return finalRes;
    }


    function addRows(data) {
        let result = [];
        for (let course in data) {
            result.push({ id: utils.generateUuid(), question: course, group_name: '' });
            const questions = data[course];
            const questionGroups = utils.groupBy(questions, 'group_name');
            for (let group in questionGroups) {
                const values = questionGroups[group];
                result.push({ id: utils.generateUuid(), question: group, group_name: '' });
                for (let v of values) {
                    result.push(v);
                }
            }
        }
        const grand = calc(data);
        setGrandResult(grand);
        const grandCategories = getSubCats(data);
        const grandCategoriesSummary = getCategorySummary(grandCategories);
        const gr = getRemark(grand);
        setGrandRemark(gr);
        table.setData(result);
        setCategories(grandCategoriesSummary)
        return result;
    }

    function getRemark(value) {
        const val = parseFloat(value);
        let res = designation?.filter(v => val >= v?.from && val <= v?.to);
        return res.length ? res[0] : {};
    }

    // function setCategories(grandCategoriesSummary) {
    //     let array = [];
    //     let result = JSON.parse(JSON.stringify(grandCategoriesSummary || '') || '{}');

    //     for (let category in result) {            
    //         const remark = getRemark(result[category]);
    //         result[category] = { description: category, value: result[category], performance: remark?.remark, includedInGrand: 'Yes' };
    //         array.push({ id: utils.generateUuid(), description: category, value: grandCategoriesSummary[category], performance: remark?.remark, includedInGrand: 'Yes' });
    //     }
    //     categoryTable.setData(array);        
    //     setCategorySummary(result);
    // }

    function setCategories(grandCategoriesSummary) {
        let array = [];
        if (!grandCategoriesSummary) return array; // Return empty array if input is null or undefined

        for (let category in grandCategoriesSummary) {
            if (grandCategoriesSummary.hasOwnProperty(category)) {
                const remark = getRemark(grandCategoriesSummary[category]);
                array.push({
                    id: utils.generateUuid(),
                    description: category,
                    value: grandCategoriesSummary[category],
                    performance: remark?.remark,
                    includedInGrand: 'Yes'
                });
            }
        }
        array.sort((a, b) => a.description.localeCompare(b.description));
        categoryTable.setData(array);
        // setCategorySummary(result);
        return array;
    }


    function getCategorySummary(gtc) {
        let result = {};
        for (let category in gtc) {
            const res = compute(gtc[category]);
            result[category] = (res?.EFiWi / res?.EFi).toFixed(2);
        }
        return result;
    }

    function getSubCats(data) {
        const gc = {};
        for (let course in data) {
            const answers = data[course];
            const groupedCategories = utils.groupBy(answers, 'group_name');
            for (let category in groupedCategories) {
                if (Array.isArray(gc[category])) {
                    gc[category]?.push(...groupedCategories[category]);
                } else {
                    gc[category] = groupedCategories[category];
                }
            }
        }
        return gc;
    }

    function calc(data) {
        let EFiWi = 0;
        let EFi = 0;
        for (let course in data) {
            const answers = data[course];
            const result = compute(answers);
            EFiWi += result?.EFiWi;
            EFi += result?.EFi;
        }
        return (EFiWi / EFi).toFixed(2);
    }

    function compute(answers) {
        let EFiWi = 0;
        let EFi = 0;
        for (let ans of answers) {//array
            for (let key in ans) {//each entry of the array is an object therefore go through that object and compare the keys to the lookup
                if (key in lookup) {
                    EFiWi += (lookup[key] * ans[key]) || 0;
                }
            }
            EFi += ans?.total;
        }
        return { EFiWi, EFi }
    }



    useMemo(() => {
        // console.log(state)
        table.setCssClasses('table-striped w-100');
        table.setColumns(columns);
        categoryTable.setColumns(categoryColumns);
        categoryTable.setCssClasses('w-75');
        fetchEvaluation();
        console.log('looping')
    }, [add.saveCompleted]);



    return (
        <>
            <AppContainer
                extra={
                    <>
                        <Button className='btn-primary' onClick={e => navigate('../course_evaluation')} icon={<i className='fas fa-arrow-left me-2' />}>Go Back</Button>
                    </>
                }
                title={'Course Evaluation Details'}
                children={
                    <div className='row'>
                        <div className='col-md-12 mb-2'>
                            <div className='mt-2 p-2 h6'>
                                <div>Lecturer: {state?.lecturer}</div>
                                <div>Academic Year: {state?.acad_year}</div>
                                <div>Course: {state?.course_name} - {state?.course_code}</div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='border-bottom mb-2 p-2'>
                                <label className='h5 fw-bold text-muted'>Grand Results: {grandResult || ''} {grandRemark?.remark && `(${grandRemark?.remark})`}</label>
                                <label className='fw-bold text-primary'>{'Grading=>'} Excellent: {'(4-3.50)'}, Very Good: {'(3.49>-3.00)'}, Good:{'(2.99>-2.50)'} Satisfactory{'(2.49>-2.00)'}, Poor{'(1.99<-1.50)'}, Very Poor{'(1.49-0.00)'}</label>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='mb-3 p-2'>
                                <label className='h5 fw-bold text-muted'>Category Summary</label>
                                {categoryTable.table}
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='table-responsive h-scrolling-wrapper'>
                                {table.table}
                            </div>
                        </div>
                    </div>
                }
                table={table}
            />            
        </>
    );
}

export default memo(Evaluation);