import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createTrackedSelector } from 'react-tracked';
import Settings from '../dependencies/custom/settings';
const store = (set) => ({
    tables_metadata: {
        method: 'post',
        table: 'tables_metadata',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'tables_metadata',
    },
    grade: {
        method: 'post',
        table: 'grade',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'grade',        
    },
    semester: {
        method: 'post',
        table: 'semester',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'semester',
    },
    acad_year: {
        method: 'post',
        table: 'acad_year',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'acad_year',
    },
    course: {
        method: 'post',
        table: 'courses',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'courses',
    },
    settings: {
        method: 'post',
        table: 'settings',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'settings',
        critfdx: ['is_public'],
        critval: ['1'],
        fields: ['*']
    },
    class_designation: {
        method: 'post',
        table: 'class_designation',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'class_designation'       
    },
    
    // category: {  
    //     method:'post',      
    //     table: 'category',
    //     url: `${Settings.backend}/bootstrap`,        
    //     storeName: 'categories',
    //     treeDataStoreName:'categoryTreeSelectData',
    //     treeDataStoreNameNoDisabled:'categoryTreeSelectDataNoDisabled'
    // },

    // reg_dist: {  
    //     method:'post',      
    //     table: 'reg_dist',        
    //     url: `${Settings.backend}/bootstrap`,        
    //     storeName: 'reg_dist',
    //     treeDataStoreName:'regDistTreeSelectData',
    //     treeDataStoreNameNoDisabled:'regDistTreeSelectDataNoDisabled'
    // },

    // filters: {  
    //     method:'post',      
    //     table: 'filters',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'filters',        
    // },

    // filter_opt: {  
    //     method:'post',      
    //     table: 'filter_opt',        
    //     url: `${Settings.backend}/bootstrap`,       
    //     storeName: 'filter_opt',        
    // },

    // filter_to_category:{
    //     method:'post',      
    //     table: 'filter_to_category',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'filter_to_category', 
    // },

    // package:{
    //     method:'post',      
    //     table: 'package',        
    //     url: `${Settings.backend}/bootstrap`,     
    //     storeName: 'package', 
    // },

    // package_to_category:{
    //     method:'post',      
    //     table: 'package_to_category',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'package_to_category', 
    // },

    // convo_starter:{
    //     method:'post',      
    //     table: 'convo_starter',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'convo_starter', 
    // },
    // user_extra_details_opt:{
    //     method:'post',      
    //     table: 'user_extra_details_opt',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'user_extra_details_opt', 
    // }
});

const useStore = create(devtools(store));

const useTrackedStore = createTrackedSelector(useStore);
export default useTrackedStore;

