
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import AppContainer from '../../components/container';

const Programs = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight']
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_programs`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'programs', fields: ['*'] });


    const columns = ([
        {
            title: 'Name',
            dataIndex: 'name',
            ...table.getColumnSearchProps('name'),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-successx border-0x' onClick={e => editRecord(record, 'programs')}><i className='fas fa-edit text-success' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'programs', where: 'id', whereType: 'closed' },
                        <Button className='btn-dangerx border-0x'>  <i className='fas fa-trash text-danger' /></Button>
                    )}
                </Space>
            },
        }
        /*{
            title: 'Year',
            dataIndex: 'acadyr',
            ...table.getColumnSearchProps('acadyr'),
            // render: (v, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./users?email=${record['owner']}#page=1`)}>{utils.truncateText(v, 8)}</a>
            // }
        },
        {
            title: 'Semester',
            dataIndex: 'semester',
            ...table.getColumnSearchProps('semester'),
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            ...table.getColumnSearchProps('start_date'),
            render: (v) => `${utils.formatDate(v)}`
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            ...table.getColumnSearchProps('end_date'),
            render: (v) => `${utils.formatDate(v)}`
        },       
        {
            title: 'Active',
            dataIndex: 'is_active',
            filters: [
                {
                    text: 'Yes',
                    value: '1'
                },
                {
                    text: 'No',
                    value: '0'
                }
            ],
            render: (v) => `${v ? 'Yes' : 'No'}`
        },       
        // {
        //     title: 'Dets.',
        //     key: 'details',
        //     render: (_, record) => {
        //         return <a className='blue-text' onClick={e => navigate(`./ad_details?advert_id=${record['custom_id']}#page=5`)}><i className='fas fa-user' /></a>
        //     },
        // },        
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <a onClick={e => editRecord(record, 'acad_calendar')}><i className='fas fa-edit' /></a>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'acad_calendar', where: 'id', whereType: 'closed' },
                        <i className='fas fa-trash' />
                    )}
                </Space>
            },
        }*/
    ]);

    const colFilters = [];

    function editRecord(record, tableName) {
        utils.renameKeys(record, keyOverrides);
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'programs') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    async function editOnOk() {
        let res = await edit.save(undefined,
            `${Settings.backend}/edit`,
            'programs',
            { tbl: 'programs', where: 'id', whereType: 'closed' },
        );
    }

    async function addOnOk() {
        let res = await add.save(`${Settings.backend}/add`, { tbl: 'programs' })
    }


    useMemo(() => {
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);


    return (
        <>
             <AppContainer
                extra={
                    <>
                        <Button className='btn-primary border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Item</Button>
                    </>
                }
                title={'Programs'}
                children={
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='table-responsive h-scrolling-wrapper'>
                                {table.tableWithHeaderFooter()}
                            </div>
                        </div>
                    </div>
                }
                table={table}
            /> 
           
            {edit.editModal('Edit Item', editOnOk)}
            {add.addModal('Add New Item', addOnOk)}
        </>
    );
}

export default memo(Programs);