import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Notifications } from 'react-push-notification';
import { ConfigProvider, theme } from 'antd';

import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  // <ConfigProvider
  //   theme={{
  //     token: {
  //       algorithm: [theme.compactAlgorithm],
  //       colorPrimary: data.colorPrimary,        
  //     },
  //     components: {
  //       Button: {
  //         colorPrimary: data.Button?.colorPrimary,          
  //       },
  //     },
  //   }}
  // >
  <BrowserRouter>
    <Notifications />
    <App />
  </BrowserRouter>
  // </ConfigProvider>
  // </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


