
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, Card } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import AppContainer from '../../components/container';

const Courses = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const addMountedCourse = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    const adminId = sessionStorage.getItem('K3050');
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_courses`,
        'post',
        'result',
        'totalCount',
        'id',
        { 'deptAlias': 'IN' },
        { table: 'courses', fields: ['*'] });


    const columns = ([
        {
            title: 'Name',
            dataIndex: 'course_name',
            ...table.getColumnSearchProps('course_name'),
        },
        {
            title: 'Code',
            dataIndex: 'course_code',
            ...table.getColumnSearchProps('course_code'),
            // render: (v, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./users?email=${record['owner']}#page=1`)}>{utils.truncateText(v, 8)}</a>
            // }
        },
        {
            title: 'Program',
            dataIndex: 'programName',
            filterSearch: true
            // ...table.getColumnSearchProps('program_id'),
            // render: (v) => `${utils.formatDate(v)}`
        },
        {
            title: 'Dept',
            dataIndex: 'dept',
            filterSearch: true
            // ...table.getColumnSearchProps('alias'),
            // render: (v) => `${utils.formatDate(v)}`
        },
        {
            title: 'SEM',
            dataIndex: 'semester_id',
            // ...table.getColumnSearchProps('semester_id'),
            // render: (v) => `${utils.formatDate(v)}`
        },
        {
            title: 'CH',
            dataIndex: 'credit_hours',
            ...table.getColumnSearchProps('credit_hours'),
        },
        {
            title: 'LVL',
            dataIndex: 'level',
            // ...table.getColumnSearchProps('level'),
            // render: (v) => `${utils.formatDate(v)}`
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                // console.log(_, record);
                return <Space size="middle">
                    <Button className='btn-primaryx border-0x' onClick={e => mountedCourse('mounted_courses', record)}><i className='fas fa-plus text-primary' /></Button>
                    <Button className='btn-successx border-0x' onClick={e => editRecord(record, 'courses')}><i className='fas fa-edit text-success' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'courses', where: 'id', whereType: 'closed' },
                        <Button className='btn-dangerx border-0x'>  <i className='fas fa-trash text-danger' /></Button>
                    )}
                </Space>
            },
        },
    ]);

    async function mountedCourse(tableName = 'mounted_courses', record) {
        if (record) {
            if (record.is_parent) {
                const res = valuesStore.getValuesBy('courses', 'parent', record?.course_code);
                const a = res.map(v => {
                    return v.course_code;
                });
                addMountedCourse.setRecord({ course_id: record?.course_code, sub_courses: a })
            } else {
                addMountedCourse.setRecord({ course_id: record?.course_code });
            }
        }
        addMountedCourse.setTblName(tableName);
        addMountedCourse.setShowModal(true);
        addMountedCourse.setSaveCompleted(false);
        addMountedCourse.addForm(tableName);
    }

    const colFilters = [
        {
            filter: 'dept',
            sql: "SELECT name,alias FROM organizational_type WHERE type IN (4,5) ORDER BY name ",
            key: 'alias',
            value: 'name'
        },
        {
            filter: 'programName',
            sql: "SELECT name,alias FROM programs WHERE show_hide=1 ORDER BY name ",
            key: 'alias',
            value: 'name'
        },
        {
            filter: 'programName',
            sql: "SELECT name,alias FROM programs WHERE show_hide=1 ORDER BY name ",
            key: 'alias',
            value: 'name'
        },
        {
            filter: 'level',
            sql: "SELECT level FROM level ORDER BY level ",
            key: 'level',
            value: 'level'
        },
        {
            filter: 'semester_id',
            sql: "SELECT alias,semester FROM semester ORDER BY id ",
            key: 'alias',
            value: 'semester'
        }
    ];

    function editRecord(record, tableName) {
        utils.renameKeys(record, keyOverrides);
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'courses') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    async function editOnOk() {
        let res = await edit.save(undefined,
            `${Settings.backend}/edit`,
            'courses',
            { tbl: 'courses', where: 'id', whereType: 'closed' },
        );
    }

    async function addOnOk() {
        // add.setRecord(r => ({ ...r, h: 8 }));
        // let res = await add.save(`${Settings.backend}/add`, { tbl: 'courses' });
        let res = await utils.requestWithReauth('post', `${Settings.backend}/save_course`, null, add.record);
        if (res.status === 'Ok') {
            add.reset();
            utils.showNotification(undefined, 'Operation succesful', 'text-success');
        } else {
            utils.showNotification(undefined, res.msg);
        }

    }

    async function addMountedOnOk() {
        let res = await utils.requestWithReauth('post', `${Settings.backend}/save_mounted_course`, null, addMountedCourse.record);
        if (res.status === 'Ok') {
            addMountedCourse.reset();
            utils.showNotification(undefined, 'Operation succesful', 'text-success');
        } else {
            utils.showNotification(undefined, res.msg);
        }
    }

    useMemo(() => {
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);


    return (
        <>
            <AppContainer
                extra={
                    <>
                        <Button className='btn-primary border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Course</Button>
                        <Button className='btn-secondary border-0' onClick={e => mountedCourse('mounted_courses')}><i className='fas fa-user-plus me-2' /> Mount Course(s)</Button>
                        <DatePicker.RangePicker onChange={v => setDateRange(v)} value={dateRange} />
                        <Button className='btn-success border-0' onClick={e => utils.filterByDate('date_inserted', dateRange, table)}><i className='fas fa-filter me-2' />Filter</Button>
                    </>
                }
                title={'Courses'}
                children={
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='table-responsive h-scrolling-wrapper'>
                                {table.tableWithHeaderFooter()}
                            </div>
                        </div>
                    </div>
                }
                table={table}
            />
            {edit.editModal('Edit Item', editOnOk)}
            {add.addModal('Add New Item', addOnOk)}
            {addMountedCourse.addModal('Mount New Course', addMountedOnOk, undefined, undefined, 800)}
        </>
    );
}

export default memo(Courses);