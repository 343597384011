import React, { useState, useEffect, memo, useMemo, useRef } from "react";
import ValuesStore from "../../store/values-store";
import { useSearchParams, Link, useLocation, useNavigate } from "react-router-dom";
import utils from "../../dependencies/custom/react-utilities";
import { Space, Button, Card } from "antd";
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import qs from "qs";
import useTable from "../../hooks/table";
import useEdit from "../../hooks/edit";
import useAdd from "../../hooks/add";
import useDelete from "../../hooks/delete";
import Settings from "../../dependencies/custom/settings";

const Uploads = props => {
  const valuesStore = ValuesStore();
  const edit = useEdit("tables_metadata", "table_name"); //make this hook be aware of where to get tbl metadata
  const add = useAdd("tables_metadata", "table_name");
  const del = useDelete();
  const { filters, filterTypes } = utils.generateTableFilters();
  const [dateRange, setDateRange] = useState([]);
  const navigate = useNavigate();
  const keyOverrides = { categoryAlias: "category" };
  const pdfsDir = "pdfs";
  //and key value that points to the table names from zustand store.
  const table = useTable(
    {
      pagination: {
        current: 1,
        pageSize: 10,
        position: ["bottomRight"],
        hideOnSinglePage: true
      },
      filters: { ...filters },
      filterTypes: { ...filterTypes }
    },
    `${Settings.backend}/get_data`,
    "post",
    "result",
    "totalCount",
    "id",
    {
      /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */
    },
    { table: "uploads", fields: ["*"] }
  );

  const columns = [
    {
      title: "File Name",
      dataIndex: "filename",
      ...table.getColumnSearchProps("filename"),
    },
    {
      title: "Programs",
      dataIndex: "programs",
      ...table.getColumnSearchProps("programs")
    },
    {
      title: "Level",
      dataIndex: "level",
      ...table.getColumnSearchProps("level")
    },
    {
      title: "Semester",
      dataIndex: "semester",
      ...table.getColumnSearchProps("semester")
    },
    {
      title: "Session",
      dataIndex: "session",
      ...table.getColumnSearchProps("session")
    },
    {
      title: "Year",
      dataIndex: "year",
      ...table.getColumnSearchProps("semester")
    },
    {
      title: "Path",
      dataIndex: "path",
      ...table.getColumnSearchProps("path"),
      render: (v, record) => {
        return (
          <a target="_blank" href={`${Settings.backend}/${v}`}>
            {utils.truncateText(v, 10)}
          </a>
        );
      }
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Button className="btn-successx border-0x" onClick={e => editRecord(record, "uploads")}>
              <i className="fas fa-edit text-success" />
            </Button>
            {del.confirm(
              `${Settings.backend}/delete`,
              record,
              "Are you sure to delete this item",
              { tableName: "uploads", where: "id", whereType: "closed", filePathDBField: 'path', container: pdfsDir },
              <Button className="btn-dangerx border-0x">
                {" "}
                <i className="fas fa-trash text-danger" />
              </Button>
            )}
          </Space>
        );
      }
    }
  ];

  function editRecord(record, tableName) {
    const storeKey = "editableRecord";
    valuesStore.setValue(storeKey, record);
    edit.setTblName(tableName);
    edit.setData(record);
    edit.setRecordKey(storeKey);
    edit.setShowModal(true);
    edit.setSaveCompleted(false);
    edit.upload.setUploadURL(`${Settings.backend}/upload_file`);
    edit.upload.setNumFiles(1);
    edit.upload.setAcceptedFiles(["application/pdf"]);
    edit.upload.setExtraUploadParams({
      operation: "update",
      table: tableName,
      rowIdFieldName: "id",
      rowIdFieldValue: record["id"],
      container: pdfsDir,
      filePathDBField: "path"
    });
    edit.recallFiles(record, "path", tableName, `${Settings.backend}/del_file_update_tbl`, "id", pdfsDir);
  }

  function addRecord(tableName = "uploads") {
    add.setTblName(tableName);
    add.setShowModal(true);
    add.setSaveCompleted(false);
    add.upload.setNumFiles(1);
    add.upload.setAcceptedFiles(["application/pdf"]);

    add.upload.setCustomBeforeUpload({
      async beforeUpload(file, b) {
        const base64 = await utils.toBase64(file);
        add.upload.setBase64FileList(files => [...files, base64]);
      }
    });
  }

  function fileDelete() {
    edit.upload.setDelFile({
      async delete(file) {
        await utils.deleteFile(file);
      }
    });

    add.upload.setDelFile({
      delete(file) {
        add.upload.setBase64FileList([]);
      }
    });
  }

  async function editOnOk() {
    let res = await edit.save(undefined, `${Settings.backend}/edit`, "uploads", {
      // let res = await edit.saveWithFiles(undefined, `${Settings.backend}/edit_uploads`, "uploads", {
      tbl: "uploads",
      where: "id",
      whereType: "closed"
    });
    edit.reset();
  }

  async function addOnOk() {
    // let res = await add.save(`${Settings.backend}/add`, { tbl: "admin" });
    await add.saveWithFiles(`${Settings.backend}/upload_documents`, {
      tbl: "uploads",
      multipleInsert: false,
      filePathDBField: "path",
      container: pdfsDir
    });
  }

  useMemo(
    () => {
      fileDelete();
      table.setColumns(columns);
      console.log("looping");
      table.fetchData();
    },
    [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]
  );

  
  return (
    <>
      {/* {console.log('render render')} */}
      <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
        <Card
          bordered={false}
          className="criclebox tablespace border-0 mb-24 p-2"
          title="Student Files"
          extra={
            <Space>
              <Button className='btn-primary border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Item</Button>
            </Space>
          }
        >
          <div className='row'>
            <div className='col-md-12'>
              <div className="table-responsive h-scrolling-wrapper mt-3">
                {table.table}
              </div>
            </div>
          </div>
        </Card>

      </div>
      {edit.editModal('Edit Item', editOnOk)}
      {add.addModal('Add New Item', addOnOk)}
    </>
  );
};

export default memo(Uploads);





