import { MailruIcon } from "react-share";

var domainSettings = {
    dbName: 'postitonline',
    dbVersion: 1,
    dbTables: [{
        tblName: 'user',
        tblOpt: { autoIncrement: true },
        tblIndexes: [{ indexName: 'user', indexOpt: { unique: true } }]
    },
    ],
    //P73/V PENKWASE, BS-0080-6744 
    appName: 'Postitonlinegh',
    appMail: 'support@postitonlinegh.com',
    numPopularToAppearOnCategoryTop: 5,
    googleClientUrl: 'https://accounts.google.com/gsi/client',
    googleClientID: '720696234161-050k7elpoios13i2ocfpu7el28k4ae5v.apps.googleusercontent.com',
    facebookAppID: 597121861801078,
    primaryColor: 'pinkx darken-4x special-color-darkx brownx darken-4x teal darken-4',
    secondaryColor: 'pinkx darken-1x special-colorx brownx darken-1x deep-turquiose',
    textColor: 'blue-grey-text',
    primaryColorHex: '#004d40',
    secondaryColorHex: '#1e847f',
    textColorHex: '#607d8b',
    
    
    // backend:'http://localhost:3002',
    // backend:'http://192.168.8.155:3002',
    // backend: 'https://smgt2.aamusted.edu.gh',     
    backend:`https://${window.location.host}`,
    // backend:`http://smgt2.aamusted.edu.gh:3002`,
    // backend:`http://${window.location.hostname}:3002`,
    checkIndex: 'https://finance.aamusted.edu.gh/tpapi/check_index'
    // checkIndex: 'https://89.116.229.137:8090/preview/finance.aamusted.edu.gh/tpapi/check_index'

}

// INSERT INTO `admin_perm` (`id`, `permission`, `table_name`, `alias`, `date_inserted`, `description`, `restricted`, `is_open`, `for_system`) VALUES (NULL, 'delete', 'scoresheet_summary_extra', 'delete_scoresheet_summary_extra', '2024-07-21 10:06:35.000000', 'For deleting grades from historical data', '0', '0', '0');
// INSERT INTO `admin_role_perm` (`id`, `role_id`, `permission_id`, `ar_link_restricted`, `date_inserted`) VALUES (NULL, 'root', 'delete_scoresheet_summary_extra', '0', '2024-07-21 10:07:41.000000'), (NULL, 'exams_officer', 'delete_scoresheet_summary_extra', '0', '2024-07-21 10:07:41.000000'), (NULL, 'hod', 'delete_scoresheet_summary_extra', '0', '2024-07-21 10:07:41.000000');

export default domainSettings;


