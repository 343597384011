import React, { memo, useMemo } from 'react';
import { Space, Card } from 'antd';

const AppContainer = ({ extra, title, children, edit, add, editOnOk, addOnOk, table }) => {
    
    useMemo(()=>{
        // table?.setCssClasses('w-100');
    },[

    ]);
    return (
        <>
            <div className='container'>
                <Card
                    bordered={false}
                    className="criclebox tablespace border-0 mb-24 p-2"
                    title={title}
                    extra={
                        <Space wrap className='p-2'>
                            {extra}
                        </Space>
                    }
                >
                    {children}
                </Card>
            </div >
            {edit?.editModal('Edit Item', editOnOk)}
            {add?.addModal('Add New Item', addOnOk)}
        </>
    );
}

export default memo(AppContainer);