
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate, json } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Input, message, Tag, Modal, Form, Tooltip, Select } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
// import qs from 'qs';
import useTable from '../../hooks/table';
// import useEdit from '../../hooks/edit';
// import useAdd from '../../hooks/add';
// import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
// import Jumbotron from '../../components/jumbotron';
import useExcel from '../../hooks/excel';
import useUpload from '../../hooks/upload';
import { confirmAlert } from 'react-confirm-alert';
import $ from 'jquery';
import useDynamicForm from '../../hooks/dynamic_form';
import CustomFunctions from '../../dependencies/custom/custom-functions';
import useBarChart from '../../hooks/barchart';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import useDrawer from '../../hooks/drawer';
import useModal from '../../hooks/modal';
import { View, Document, Page, Image, Text, PDFViewer, Font } from '@react-pdf/renderer';

const MarkEntry = (props) => {
    const valuesStore = ValuesStore();
    // const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    // const add = useAdd('tables_metadata', 'table_name');
    // const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const navigate = useNavigate();
    // const keyOverrides = { categoryAlias: 'category' };
    const [sheetInfo, setSheetInfo] = useState({});
    const [scheme, setScheme] = useState([]);
    const [prgEduLevel, setPrgEduLevel] = useState(undefined);
    const uploadRef = useRef(null);
    const uploadMapRef = useRef(null);
    // const [btnLoadiing, setBtnLoadiing] = useState(false);
    const [dataHasError, setDataHasError] = useState(false);
    const [templateHeadings, setTemplateHeadings] = useState({ 'IndexNumber': 'index_no', 'Firstname': 'fname', 'Lastname': 'lname', 'Middlename': 'mname', 'HasRegistered': 'hasRegistered' });
    let sheetHeadings = { 'IndexNumber': '', 'Firstname': '', 'Lastname': '', 'Middlename': '', 'HasRegistered': '' };
    const [btnPermissions, setBtnPermissions] = useState({});
    const [gradeChangeRecord, setGradeChangeRecord] = useState({});
    const dynamicFormName = 'gradeChange';
    const dynamicForm = useDynamicForm(dynamicFormName, null, { text: 'Submit', type: 'primary' }, null, false);
    const [apiResponse, setApiResponse] = useState(undefined);
    const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
    const barchart = useBarChart();
    const [analysisDataTable, setAnalysisDataTable] = useState();
    const [analysisStatTable, setAnalysisStatTable] = useState();
    const printableAnalysis = useRef();
    const studentDetailsDrawer = useDrawer();

    const [isOwner, setIsOwner] = useState(false);
    const modal = useModal();
    const [uploadMapExcelResult, setUploadMapExcelResult] = useState();
    const [uploadMapJSX, setUploadMapJSX] = useState();
    const [excelHeadings, setExcelHeadings] = useState({});
    const [systemHeadings, setSystemHeadings] = useState({});
    const [uniqueColumnFromExcel, setUniqueColumnFromExcel] = useState(undefined);
    const [uploadedExcelSheetHeadings, setUploadedExcelSheetHeadings] = useState();

    const resultPrintDrawer = useDrawer();
    const [schoolDetails, setSchoolDetails] = useState();
    const { state } = useLocation();

    const snWidth = 3;
    const indexWidth = 11;
    const namesWidth = 30;

    //and key value that points to the table names from zustand store.  
    const table = useTable({
        pagination: {
            current: 1,
            pageSize: 10,
            hideOnSinglePage: true,
            position: ['bottomRight'],
        },
        filters: { ...filters },
        filterTypes: { ...filterTypes }
    }, null, null, null, null, 'id', null, null);

    const excel = useExcel();
    const upload = useUpload();
    const uploadMap = useUpload();

    let columns = ([
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-primary border-0' onClick={e => CustomFunctions.viewStudentBasicData(record, studentDetailsDrawer, valuesStore)}><i className='fas fa-eye' /></Button>
                </Space>
            },
        },
        {
            title: 'Tag',
            dataIndex: 'hasRegistered',
            filters: [
                {
                    text: 'REG',
                    value: 'Yes'
                },
                {
                    text: 'UNR',
                    value: 'No'
                }
            ],
            onFilter: (v, record) => {
                return record.hasRegistered === v;
            },
            render: (value, record) => {
                return <Tag className='rounded' color={value == 'Yes' ? 'Green' : 'Red'}>
                    {value == 'Yes' ? 'REG' : 'UNR'}
                </Tag>
            },
        },
        {
            title: 'IndexNo.',
            dataIndex: 'index_no',
            ...table.getColumnSearchProps('index_no'),
        },
        {
            title: 'Firstname',
            dataIndex: 'fname',
            ...table.getColumnSearchProps('fname')
        },
        {
            title: 'Lastname',
            dataIndex: 'lname',
            ...table.getColumnSearchProps('lname')
        },
        {
            title: 'Middlename',
            dataIndex: 'mname',
            ...table.getColumnSearchProps('mname')
        }
    ]);


    async function getSheet() {
        if (apiResponse) {
            return apiResponse;
        }
        // let SQP = valuesStore.getValue('sheetQueryParams');
        // SQP = SQP.length ? SQP : JSON.parse(localStorage.getItem('sheetQueryParams'));
        let SQP = state;
        if (valuesStore.getValue('marks').length >= 1) {
            valuesStore.setValue('marks', []);
        }
        const data = { course_lecturer_id: SQP.courseLecturerID, staff_id: SQP.lecturerID, acad_year: SQP.acad_year };
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_sheet_general`, null, data);

        if (res.status === 'Error') {
            utils.showNotification(undefined, res.msg);
            return;
        }
        const { list, marks, scheme, sheetDetails, newStudentsList, programEduLevel, isOwner } = res.result;
        setScheme(scheme);
        setPrgEduLevel(programEduLevel);
        setIsOwner(isOwner);

        setSheetInfo(sheetDetails[0] ?? {});

        await appendCols(scheme);
        if (newStudentsList.length >= 1) {
            setupList(list, scheme, marks, programEduLevel, newStudentsList, isOwner);
        }
        setApiResponse(res.result);
    }


    function getGrades(programEduLevel) {
        return new Promise((resolve, reject) => {
            const timer = setInterval(() => {
                const grades = valuesStore.getValuesBy('grade', 'edu_level', programEduLevel);
                if (grades.length > 0) {
                    resolve(grades);
                    clearInterval(timer);
                }
            }, 500);

        });
    }

    function setGrade(obj, grades) {
        grades.forEach(v3 => {
            if (obj.total >= v3.marks_from && obj.total <= v3.marks_to) {
                obj['grade'] = v3.grade;
                obj['grade_points'] = v3.grade_points;
            }
        });
    }

    async function setupList(list, scheme, marks, programEduLevel, newStudentsList, isOwner) {
        let data = [];
        const m = valuesStore.getValue('marks');
        // console.log(m);        
        if (m.length == 0) {
            const grades = await getGrades(programEduLevel);
            //go through similarStudents array            
            newStudentsList?.forEach((v, i) => {
                //go through list array (i.e. registered student array) and find the intersection of it and newStudentsList arrays
                //using the index_no field

                //the intersections are the registered students so turn the hasRegistered flag on for those students
                // let hasRegistered = 'No';
                // for (let j = 0; j < list.length; j++) {
                //     const registeredStudent = list[j];
                //     const indexNo = registeredStudent.index_no;
                //     if (indexNo == v.index_no) {
                //         hasRegistered = 'Yes';
                //         break;
                //     }
                // }                
                let obj = {
                    id: `${i}_${v?.index_no}`,
                    index_no: v?.index_no,
                    fname: v?.fname,
                    lname: v?.lname,
                    mname: v?.mname,
                    sex: v?.sex,
                    total: 0,
                    hasRegistered: 'Yes',
                    hasError: 0,
                    program: v?.program
                };
                scheme.forEach(v1 => {
                    //initialize marks to zeros
                    obj[v1.custom_id] = 0;

                    //use scheme labels as keys and custom_ids as values for our template headings
                    //the labels are what will be exported as the heading of the excel sheet
                    setTemplateHeadings(r => ({ ...r, [v1.label]: v1.custom_id }));

                });

                //thus, if student marks found 
                marks.forEach((v2) => {
                    if (v2.student_id === obj.index_no) {
                        //now override the zero marks with the real marks 
                        //set marks based on scheme_ids
                        //scheme_ids have been set and initiliazed to 0 in the loop above                        
                        obj[v2.scheme_id] = parseFloat(v2?.marks || 0);
                    }
                });

                //calc total after setting all marks 
                //IC are determined here. i.e. when at least one of the marks is 0
                calcTotalMarks(obj, scheme);

                setGrade(obj, grades);
                data.push(obj);
            });//student.forEach            
            valuesStore.setValue('marks', data);
            // setMarksAsInput(scheme, programEduLevel, isOwner);
        }
        // setMarksAsInput(scheme, programEduLevel, isOwner);
    }//end function

    function calcTotalMarks(obj, s = scheme) {
        if (!zeroMarkFound(obj, s)) {
            s.forEach(v1 => {
                const marksKeyName = v1?.custom_id;
                obj.total += parseFloat(obj[marksKeyName] || 0);
            });
        }
    }

    function setMarksAsInput(scheme, programEduLevel, isOwner) {
        const finalData = valuesStore.getValue('marks')?.map((v) => {
            // let newObject = { ...v, grade: <label className='grade'>{v.grade}</label>, total: <label className='total'>{v.total}</label> };//a row that contains a particular student's data            
            let newObject = { ...v };
            const indexNo = v?.index_no;
            scheme.forEach(v1 => {
                const marksKeyName = v1?.custom_id;
                const marksAllotted = v1?.marks_allotted;//mark set
                const value = v[marksKeyName];//student mark
                const val = parseFloat(value || 0);//student mark parsed
                const m = parseFloat(marksAllotted || 0);//mark set parsed
                const dataKey = `${indexNo}_${marksKeyName}`;
                let error = '';
                if (val > m || val < 0) {
                    error = 'border-warning bg-warning';
                    newObject[v1.custom_id] = <label className={`${error} px-2 py-1`}>{value}</label>
                }
                //if sheet hasn't been submitted, override schemes with input element that takes the scheme's mark value as it value
                if (!sheetInfo.submitted && isOwner) {
                    newObject[v1.custom_id] = <Input value={value || undefined} key={dataKey} id={dataKey} type='number' placeholder='0' className={error} onChange={e => changeMark(e.target.value, marksKeyName, indexNo, marksAllotted, scheme, programEduLevel, e)} />;
                }
            });
            return newObject;
        });
        table.setData(finalData);
    }


    function zeroMarkFound(record, s = scheme) {
        //at least one zero mark found means IC
        let zeroFound = false;
        for (let j = 0; j < s.length; j++) {
            const schemeInfo = s[j];
            const marksKeyName = schemeInfo?.custom_id;
            const marks = parseFloat(record[marksKeyName] || 0);

            if (marks === 0) {
                zeroFound = true;
                break;
            }
        }
        return zeroFound
    }

    function changeMark(value, marksKeyName, indexNo, marksAllotted, scheme, programEduLevel, e) {
        //marks cannot go beyound the marks allocated for the scheme
        const v = parseFloat(value || 0);
        const m = parseFloat(marksAllotted || 0);
        if (v > m || v < 0) {
            utils.showNotification(undefined, 'Marks is out of range');
            valuesStore.updateArrayObjectValue('marks', 'index_no', indexNo, 'hasError', 1);
            valuesStore.updateArrayObjectValue('marks', 'index_no', indexNo, 'rowClasses', 'unique-color');
        } else {
            valuesStore.updateArrayObjectValue('marks', 'index_no', indexNo, 'hasError', 0);
            valuesStore.updateArrayObjectValue('marks', 'index_no', indexNo, 'rowClasses', '');
        }

        //update mark for a particular scheme. marksKeyName is that particular mark we are updating
        valuesStore.updateArrayObjectValue('marks', 'index_no', indexNo, marksKeyName, parseFloat(value || 0) || 0);
        //recalculate and update the total after updating mark
        let record = valuesStore.getArrayObjectsValue('marks', 'index_no', indexNo);
        let total = 0;

        //at least one zero mark found means IC
        //only calculate for total mark when no zero mark is found
        if (!zeroMarkFound(record)) {
            scheme.forEach(v1 => {
                const marksKeyName = v1?.custom_id;
                total += parseFloat(record[marksKeyName] || 0);
            });
        }
        valuesStore.updateArrayObjectValue('marks', 'index_no', indexNo, 'total', total);
        //update the grade for the new total
        const grades = valuesStore.getValuesBy('grade', 'edu_level', programEduLevel);
        setGrade(record, grades);
    }

    async function appendCols(scheme) {
        const hasPerm = await utils.hasPermission('view_sheet_marks_and_grade', null, valuesStore);
        if (hasPerm) {
            scheme.forEach((v) => {
                columns.push(
                    {
                        title: `${v?.label}(${v?.marks_allotted})`,
                        dataIndex: v?.custom_id,
                    },
                );
            });
            columns.push(
                {
                    title: 'Total',
                    dataIndex: 'total',
                    sorter: {
                        compare: (a, b) => a.total - b.total,
                    }
                },
                {
                    title: 'Grade',
                    dataIndex: 'grade',
                    sorter: {
                        compare: (a, b) => b.total - a.total,
                    },
                    ...table.getColumnSearchProps('grade')
                });
            if (sheetInfo.submitted && sheetInfo.status === 'published') {
                columns.push(
                    {
                        title: 'GR',
                        key: 'action',
                        render: (_, record) => {
                            if (sheetInfo.submitted && sheetInfo.status === 'published') {
                                return <Tooltip title="Grade Request">
                                    <Button onClick={e => gradeRequest(record)}><a><i className='fas fa-edit me-1 text-success' /></a></Button>
                                </Tooltip>
                            }
                        },
                    }
                )
            }
        }
        table.setColumns(columns);
    }

    function gradeRequest(record) {
        setGradeChangeRecord(record);
        dynamicForm.setModalTitle(`Grade change for ${record.index_no}`);
        let formObject = {};
        dynamicForm.setFormChildren(
            <div className='d-flex flex-column w-100'>
                {scheme.map(v => {
                    formObject[v.custom_id] = record[v.custom_id];
                    return <Form.Item
                        key={v.custom_id}
                        name={v.custom_id}
                        label={v.label}
                        rules={[
                            {
                                required: true,
                                async validator(object, value) {
                                    if (!value || parseFloat(value || 0) > parseFloat(v.marks_allotted || 0) || parseFloat(value || 0) < 0) {
                                        return Promise.reject(new Error('Mark is required and cannot go above or below the allocated marks'));
                                    }
                                }
                            },
                            {
                                required: true,
                            }
                        ]}>
                        <Input type={'number'} />
                    </Form.Item>
                })}
                <Form.Item
                    key={'remarks'}
                    name={'remarks'}
                    label={'Remarks'}
                    rules={[
                        {
                            required: true,
                        }
                    ]}>
                    <Input.TextArea placeholder="Remarks..." autoSize />
                </Form.Item>
                <Form.Item
                    key={'type'}
                    name={'type'}
                    label={'Type'}
                    rules={[
                        {
                            required: true,
                        }
                    ]}>
                    <Select
                        options={[
                            {
                                value: 'grade_change',
                                label: 'Grade Change',
                            },
                            {
                                value: 'resit',
                                label: 'Resit'
                            },
                        ]}
                    />
                </Form.Item>
            </div>
        );
        dynamicForm.form.setFieldsValue(formObject);
        dynamicForm.setShowModal(true);
    }

    function gradeChangeSubmit() {
        dynamicForm.setFormSubmit({
            async onFormSubmit(values) {
                //delete the form name as a key from the values. form fields are not dynamically generated and would not be assigned to the form name
                delete values[dynamicFormName];
                const remarks = values?.remarks;//get the remarks and delete it as well so that we're only left with marks 
                delete values['remarks'];
                const type = values?.type;
                delete values['type'];
                const studentID = gradeChangeRecord?.index_no;

                const data = { studentID, remarks, values, type };
                let res = await utils.requestWithReauth('post', `${Settings.backend}/save_grade_change_request_general`, null, data);
                if (res.status === 'Ok') {
                    utils.showNotification(undefined, 'Operation successfull', 'text-success');
                    dynamicForm.setShowModal(false);
                } else {
                    utils.showNotification(undefined, res.msg);
                }
            }
        });
    }


    useMemo(() => {
        setTimeout(() => {
            //the data to be presented on the table can have certain css classes so we inform
            //table hook to treat 'rowClasses' as the key for the css classes values in the data object
            table.setRowClassNameKey('rowClasses');
            if (!apiResponse) {
                getSheet();
            }

            setMarksAsInput(scheme, prgEduLevel, isOwner);
            straightUpload();
            mapUpload();


            console.log('render');
            const permList = ['show_download_scoresheet_list',
                'show_download_scoresheet_template',
                'show_upload_scoresheet_template',
                'show_save_mark_button',
                'show_submit_mark_button',
                'show_approve_button',
                'show_publish_button',
                'show_unpublish_button',
                'show_release_sheet_button',
                'show_sheet_print_button'];
            permList.forEach(async v => {
                const perm = await utils.hasPermission(v, null, valuesStore);
                setBtnPermissions(r => ({ ...r, [v]: perm }))
            });
            gradeChangeSubmit();

            const institution = valuesStore.getArrayObjectsValue('settings', 'prop', 'INSTITUTION_DETAILS')?.value;
            if (institution) {
                setSchoolDetails(JSON.parse(institution));
            }
        }, 500);
    }, [gradeChangeRecord, sheetInfo.submitted, valuesStore.getValue('marks'), templateHeadings]);

    function straightUpload() {
        upload.setCustomBeforeUpload({
            async beforeUpload(file, acceptedFileTypes) {
                const result = await excel.importXLSX(file);
                utils.showNotification(undefined, 'File upload successfull', 'text-success');
                const grades = await getGrades(prgEduLevel);

                let data = result.sort((a, b) => a.IndexNumber - b.IndexNumber).map((v, i) => {
                    let obj = { total: 0, hasError: 0, id: `${i}_${v?.IndexNumber}`, };
                    for (let key in templateHeadings) {
                        // This is where the mapping of the excel to the object takes place
                        // the scheme's custom_id    label on the excel to get the value
                        obj[templateHeadings[key]] = v[key];
                    }
                    scheme.forEach(r => {
                        const mark = parseFloat(v[r?.label] || 0);
                        const marksAllotted = parseFloat(r.marks_allotted || 0);
                        const marksKeyName = r?.custom_id;
                        obj[marksKeyName] = mark;
                        if (mark > marksAllotted || mark < 0) {
                            obj['hasError'] = 1;
                            obj['rowClasses'] = 'unique-color';
                            setDataHasError(true);
                        }
                    });
                    calcTotalMarks(obj);
                    setGrade(obj, grades);
                    return obj;
                });
                checkErrorsAndsetDataToStore(data);
            }
        });
    }

    function checkErrorsAndsetDataToStore(data) {
        const dataFromDB = valuesStore.getValue('marks');
        let recordNotInDB = [];
        //excel data
        data = data?.map(v => {
            const excelIndexNo = v.index_no;
            let dataFoundInDB = false;
            for (let m = 0; m < dataFromDB.length; m++) {
                const dbIndexNo = dataFromDB[m]?.index_no;
                const dbData = dataFromDB[m];
                if (dbIndexNo == excelIndexNo) {
                    const fname = dbData?.fname;
                    const lname = dbData?.lname;
                    const mname = dbData?.mname;
                    const sex = dbData?.sex;
                    const hasRegistered = dbData?.hasRegistered;
                    const hasError = v.hasError || dbData?.hasError;
                    const rowClasses = v.rowClasses || dbData?.rowClasses;

                    dataFoundInDB = true;
                    return { ...v, fname, lname, mname, sex, hasRegistered, hasError, rowClasses };
                }
            }
            if (!dataFoundInDB)
                recordNotInDB.push({ ...v, hasError: 1, rowClasses: 'amber darken-2' });
        }).filter(v => v);//filter out undefineds

        if (data.length) {
            const duplicates = utils.getArrayObjectsDuplicates(data).duplicates;
            data.forEach((v) => {
                const indexNo = v.index_no;
                if (duplicates.includes(indexNo)) {
                    v['hasError'] = 1;
                    v['rowClasses'] = 'deep-purple lighten-1';
                    setDataHasError(true);
                }
            });
        }

        //set the data after processing. this data can be downloaded as excel
        //this will cause rerendering allowing us to see the data from excel
        valuesStore.setValue('marks', [...data, ...recordNotInDB]);
    }

    function mapUpload() {
        modal.setTitle('Sheet column mapping');
        uploadMap.setCustomBeforeUpload({
            async beforeUpload(file, acceptedFileTypes) {
                const result = await excel.importXLSX(file);
                utils.showNotification(undefined, 'File upload successfull', 'text-success');
                if (!result.length) {
                    return;
                }
                let excelSheetDropdowns = [];
                let systemSheetDropdowns = [];
                setUploadMapExcelResult(result);
                const resultSample = result[0];
                const indexNumberLen = valuesStore.getArrayObjectsValue('settings', 'prop', 'indexNumberLen')?.value;
                const keysOnExcelSheet = Object.keys(resultSample).map(v => {
                    if (!isNaN(resultSample[v]) && resultSample[v].length == indexNumberLen) {
                        setUniqueColumnFromExcel(v);
                    }
                    return { value: v, label: v };
                });

                const keysOnSystemSheet = Object.keys(templateHeadings).map(v => {
                    return { value: templateHeadings[v], label: v };
                });
                // console.log(keysOnExcelSheet,keysOnSystemSheet);
                keysOnExcelSheet.forEach((v, i) => {
                    excelSheetDropdowns.push(
                        <Select
                            className='mb-2'
                            key={`excel_${i}`}
                            placeholder="Select excel column"
                            onChange={e => setExcelHeadings(r => ({ ...r, [`Select_${i}`]: e }))}
                            style={{
                                width: '100%',
                            }}
                            options={keysOnExcelSheet}
                        />
                    );
                    systemSheetDropdowns.push(
                        <Select
                            className='mb-2'
                            key={`system_${i}`}
                            placeholder="Select system column"
                            onChange={e => setSystemHeadings(r => ({ ...r, [`Select_${i}`]: e }))}
                            style={{
                                width: '100%',
                            }}
                            options={keysOnSystemSheet}
                        />
                    );
                });
                setUploadedExcelSheetHeadings(keysOnExcelSheet);
                modal.setContent(<div className='row'>
                    <div className='col-md-6'>
                        <label>Excel Column</label>
                        {excelSheetDropdowns}
                    </div>
                    <div className='col-md-6'>
                        <label>System Column</label>
                        {systemSheetDropdowns}
                    </div>
                </div>);
                modal.setOpen(true);
            }
        });
    }

    async function uploadMapModalOk() {
        const grades = await getGrades(prgEduLevel);
        const columnsMap = {};
        for (let key in excelHeadings) {
            columnsMap[excelHeadings[key]] = systemHeadings[key];
        }
        // console.log(columnsMap,uploadMapExcelResult,uniqueColumnFromExcel);
        let data = uploadMapExcelResult?.sort((a, b) => a?.[uniqueColumnFromExcel] - b?.[uniqueColumnFromExcel])?.map((v, i) => {
            let obj = { total: 0, hasError: 0, id: `${i}_${v?.[uniqueColumnFromExcel]}`, index_no: v?.[uniqueColumnFromExcel], };

            for (let key in columnsMap) {
                // This is where the mapping of the excel to the object takes place
                // the scheme's custom_id    label on the excel to get the value
                obj[columnsMap[key]] = parseFloat(v[key] || 0);
            }

            scheme.forEach(r => {
                const marksAllotted = parseFloat(r.marks_allotted || 0);
                const marksKeyName = r?.custom_id;
                const mark = parseFloat(obj[marksKeyName] || 0);
                if (mark > marksAllotted || mark < 0) {
                    obj['hasError'] = 1;
                    obj['rowClasses'] = 'unique-color text-white';
                    setDataHasError(true);
                }
            });
            calcTotalMarks(obj);
            setGrade(obj, grades);
            return obj;
        });
        checkErrorsAndsetDataToStore(data);
    }

    function onUploadExcel() {
        if (!isOwner) {
            utils.showNotification(undefined, 'Action is forbidden. You must own the sheet to be able to upload scores');
            return;
        }
        if (!sheetInfo.submitted) {
            const element = upload.uploaderHTMLElement(uploadRef.current);
            $(element).trigger('click');
        } else {
            utils.showNotification(undefined, 'Action is forbidden after sheet has been submitted');
        }
    }

    function onUploadExcelMap() {
        if (!isOwner) {
            utils.showNotification(undefined, 'Action is forbidden. You must own the sheet to be able to upload scores');
            return;
        }
        if (!sheetInfo.submitted) {
            const element = uploadMap.uploaderHTMLElement(uploadMapRef.current);
            $(element).trigger('click');
        } else {
            utils.showNotification(undefined, 'Action is forbidden after sheet has been submitted');
        }
    }



    function getSheetWithTranslatedHeading() {
        const d = valuesStore.getValue('marks');
        // const d = table.data;        
        const data = d.map(v => {
            let obj = {};
            for (let key in templateHeadings) {
                obj[key] = v[templateHeadings[key]];
            }
            // obj = { ...obj, Grade: v.grade, GradePoints: v.grade_points };
            obj = { ...obj, Grade: v.grade };
            return obj;
        });
        return data;
    }

    function calcRemColsWidth(data) {
        const excpCols = ['Firstname', 'Lastname', 'Middlename', 'IndexNumber'];
        const keys = Object.keys(data);
        const length = keys?.length;
        const exceptionals = snWidth + indexWidth + namesWidth;
        let remainingCols = 0;
        for (let i = 0; i < length; i++) {
            const key = keys[i];
            if (!excpCols.includes(key)) {
                remainingCols += 1;
            }
        }
        const otherWidth = (100 - (exceptionals)) / remainingCols;
        return otherWidth;
    }

    function capitalizeFirst(word) {
        return word.charAt(0).toUpperCase()
            + word.slice(1)
    }

    function pageNumber() {
        return <Text style={{
            position: 'absolute',
            fontSize: 12,
            bottom: 15,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
        }} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
        )} fixed />;
    }

    function rows(data, otherColWidth) {
        return data?.map((v, index) => {
            delete v['HasRegistered'];
            let fname, sname, mname = '';
            let sn = <Text key={utils.generateUuid()} style={{ width: `${snWidth}%` }}>{(index + 1)}</Text>;
            let indexNo = undefined;
            let grade = undefined;
            let exam = undefined;
            let CA = [];
            let tca = 0;
            let examTotal = 0;

            for (let j in v) {
                if (j == 'Firstname') {
                    fname = capitalizeFirst(v[j]?.toLowerCase());
                } else if (j == 'Lastname') {
                    sname = v[j]?.toUpperCase();
                } else if (j == 'Middlename') {
                    mname = capitalizeFirst(v[j]?.toLowerCase());
                }
            }
            const names = `${sname}, ${fname} ${mname}`;
            const theNames = <Text key={utils.generateUuid()} style={{ width: `${namesWidth}%` }}>{names}</Text>;
            for (let j in v) {
                if (j == 'IndexNumber') {
                    indexNo = <Text key={utils.generateUuid()} style={{ width: `${indexWidth}%` }}>{v[j]}</Text>;
                }
                else if (j == 'Grade') {
                    grade = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{v[j]}</Text>;
                } else {
                    for (let i = 0; i < scheme.length; i++) {
                        const label = scheme[i].label;
                        if (label == 'Exam') {
                            examTotal = parseFloat(v[label] || 0);
                            exam = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{v[label]}</Text>;
                        }
                        if (j == label && label != 'Exam') {
                            tca += parseFloat(v[label] || 0);
                            CA.push(<Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{v[label]}</Text>);
                        }
                    }
                }
            }
            const totalCA = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{tca}</Text>;
            const total = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{parseFloat(tca || 0) + parseFloat(examTotal || 0)}</Text>;

            return <View key={utils.generateUuid()} style={{ display: 'flex', flexDirection: 'row', fontSize: '9px', borderBottom: '1px solid grey' }}>
                {sn}{indexNo}{theNames}{CA}{totalCA}{exam}{total}{grade}
            </View>
        });
    }


    function firstRowHeader(data, otherColWidth) {
        delete data['HasRegistered'];
        let sn = <Text key={utils.generateUuid()} style={{ width: `${snWidth}%` }}>SN</Text>;
        let indexNo = undefined;
        let names = undefined;
        let grade = undefined;
        let exam = undefined;
        let CA = [];
        let tca = 0;
        let examTotal = 0;
        let nameFieldCreated = false;
        for (let key in data) {
            if (key == 'IndexNumber') {
                indexNo = <Text key={utils.generateUuid()} style={{ width: `${indexWidth}%` }}>{key}</Text>;
            } else if (['Firstname', 'Lastname', 'Middlename'].includes(key)) {
                if (!nameFieldCreated) {
                    nameFieldCreated = true;
                    names = <Text key={utils.generateUuid()} style={{ width: `${namesWidth}%` }}>Name</Text>;
                }
            } else if (key == 'Grade') {
                grade = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{key}</Text>;
            } else {
                for (let i = 0; i < scheme.length; i++) {
                    const label = scheme[i].label;
                    const marks = scheme[i].marks_allotted;
                    if (label == 'Exam') {
                        examTotal = marks;
                        exam = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{`${label}(${marks})`}</Text>;
                    }
                    if (key == label && label != 'Exam') {
                        tca += parseFloat(marks || 0);
                        CA.push(<Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{`${label}(${marks})`}</Text>);
                    }
                }
            }
        }
        const totalCA = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{`CA Total (${tca})`}</Text>;
        const total = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{`Total (${parseFloat(tca || 0) + parseFloat(examTotal || 0)})`}</Text>;
        return <View key={utils.generateUuid()} style={{ display: 'flex', flexDirection: 'row', fontSize: '8px', backgroundColor: 'black', color: 'white', marginTop: '1px' }} fixed>
            {sn}{indexNo}{names}{CA}{totalCA}{exam}{total}{grade}
        </View>
    }

    function header() {
        return <>
            {/* The Water mark */}
            <View style={{ position: 'absolute', width: '100%' }} fixed>
                <Image style={{ opacity: '.7', height: '100vh' }} src={`${Settings.backend}/${schoolDetails?.transcriptWaterMarkImage}`} />
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', border: 'solid black', borderBottom: '1px' }}>
                {/* logo */}
                <Image style={{ width: '13%', border: 'solid black', borderRight: '1px' }} src={`${Settings.backend}/${schoolDetails.logo}`} />
                {/* heading */}
                <View style={{ width: '82%', fontSize: '10pt', marginTop: '10px' }}>
                    <Text style={{ textAlign: 'center', fontSize: '15pt', fontWeight: 'bold' }}>{schoolDetails.name}</Text>
                    <Text style={{ textAlign: 'center' }}>{schoolDetails.pobox}</Text>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} >
                        <Text style={{ textAlign: 'right' }}>{schoolDetails.website}</Text>
                    </View>
                </View>
            </View>
        </>
    }

    function footer() {
        return <View key={utils.generateUuid()} style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', fontSize: '9px', justifyContent: 'space-between', marginTop: '3%' }}>
            <View style={{ display: 'flex', flexDirection: 'column' }} >
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                    <Text>H.O.D's Signature: </Text>
                    <Text style={{ width: '150px', borderBottom: '1px solid black' }} />
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '3%' }} >
                    <Text>Date: </Text>
                    <Text style={{ width: '150px', borderBottom: '1px solid black' }} />
                </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column' }} >
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                    <Text>Examiner's Signature: </Text>
                    <Text style={{ width: '150px', borderBottom: '1px solid black' }} />
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '3%' }} >
                    <Text>Date: </Text>
                    <Text style={{ width: '150px', borderBottom: '1px solid black' }} />
                </View>
            </View>
        </View>
    }

    function lecturerDetails() {
        return <View style={{ display: 'flex', flexDirection: 'column', fontSize: '9pt' }}>
            <Text>Course: {sheetInfo?.course_code}/{sheetInfo?.course_name}/{sheetInfo?.program}/{sheetInfo?.session}</Text>
            <Text>Semester: {sheetInfo?.semester}</Text>
            <Text>Academic Year: {sheetInfo?.acad_year}</Text>
            <Text>Lecturer: {sheetInfo?.name}</Text>
        </View>

    }


    function printResults() {
        const data = getSheetWithTranslatedHeading();
        const grouped = utils.groupBy(data, 'HasRegistered');
        const reg = grouped['Yes'];
        const unreg = grouped['No'];
        const otherColWidth = calcRemColsWidth(reg[0]);
        const h = firstRowHeader(reg[0], otherColWidth);
        const regList = rows(reg, otherColWidth);
        const unRegList = rows(unreg, otherColWidth);
        const res = <PDFViewer width={'100%'} height={'700px'}>
            <Document style={{ margin: '20px' }}>
                <Page key={utils.generateUuid()} size="A4" style={{ padding: '20px', border: '1px solid black' }}>
                    {header()}
                    {lecturerDetails()}
                    {h}
                    {regList}
                    <Text style={{ color: 'red' }}>Unregistered Student List</Text>
                    {unRegList}
                    {footer()}
                    {pageNumber()}
                </Page>
            </Document>
        </PDFViewer>;
        resultPrintDrawer.setTitle('Print PDF Report');
        resultPrintDrawer.setWidth(800);
        resultPrintDrawer.setContent(res);
        resultPrintDrawer.setOpen(true);
        resultPrintDrawer.setPlacement('right');
    }

    function onDownloadSheet() {
        const data = getSheetWithTranslatedHeading();
        let headings = Object.keys(templateHeadings);
        // headings.push('Grade', 'GradePoints');
        headings.push('Grade');
        excel.exportXLSX(headings, data, 'sheet1', 'template.xlsx');
    }

    function onDownloadList() {
        const data = valuesStore.getValue('marks');
        let obj = data.map(v => {
            sheetHeadings['IndexNumber'] = v?.index_no;
            sheetHeadings['Firstname'] = v?.fname;
            sheetHeadings['Lastname'] = v?.lname;
            sheetHeadings['Middlename'] = v?.mname;
            sheetHeadings['HasRegistered'] = v?.hasRegistered;
            return { ...sheetHeadings };
        });
        const headings = Object.keys(sheetHeadings);
        excel.exportXLSX(headings, obj, 'sheet1', 'file.xlsx');
    }

    function errorFound(data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].hasError) {
                return true;
            }
        }
        return false;
    }

    async function onSubmit() {
        confirmAlert({
            title: 'Confim sheet submission',
            message: 'Continuing will lock the sheet. Click on Yes to continue. Click on No to save',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { onSave(true) }
                },
                {
                    label: 'No',
                    onClick: () => { onSave() }
                }
            ]
        });
    }


    async function onSave(submit = false) {
        const marks = valuesStore.getValue('marks');
        const duplicates = utils.getArrayObjectsDuplicates(marks).duplicates;
        const hasPerm = await utils.hasPermission('can_submit_all_sheets', null, valuesStore);
        if (!hasPerm && !apiResponse.isOwner) {
            utils.showNotification(undefined, 'Action forbidden. You are not the owner of this sheet');
            return;
        }

        if (duplicates.length) {
            utils.showNotification(undefined, `Duplicate rows found [${duplicates.join(',')}]`);
            return;
        }
        if (errorFound(marks)) {
            utils.showNotification(undefined, 'Erroneous marks detected');
            return;
        }

        let summaryDetails = {
            acad_year: sheetInfo.acad_year,
            session: sheetInfo.session,
            course_id: sheetInfo.course_code,
            lecturer_id: sheetInfo.staff_id,
            semester_id: sheetInfo.semester_id,
            course_lecturer_id: sheetInfo.custom_id,
        };

        let summary = [];
        const data = marks.map(v => {
            let sd = { ...summaryDetails };
            let finalArr = [];
            if (!v.hasError) {
                scheme.forEach(s => {
                    const schemeID = s.custom_id;
                    let arr = [v.index_no, schemeID, v[schemeID] || 0];
                    finalArr.push(arr);
                });
                sd['student_id'] = v.index_no;
                sd['total_obtained_marks'] = v.total || 0;
                sd['grade'] = v.grade;
                sd['grade_points'] = v.grade_points;
                sd['credit_hours'] = sheetInfo.credit_hours;
                sd['credit_grade_point'] = parseFloat((sheetInfo.credit_hours * v.grade_points) || 0);
                sd['has_resat'] = sheetInfo.mounted_for === 'resit' ? 1 : 0;
                sd['mounted_for'] = sheetInfo.mounted_for;
                sd['program_id'] = v?.program;
                summary.push(sd);
            }
            return finalArr;
        });

        const res = await utils.requestWithReauth('post', `${Settings.backend}/save_marks_general`, null, { data, summary, summaryDetails, submit });

        if (res.status == 'Ok') {
            if (res.submit) {
                navigate(0);
            }
            utils.showNotification(undefined, 'Operation successful', 'text-success');
        } else {
            utils.showNotification(undefined, res.msg);
        }
    }

    function approve() {
        CustomFunctions.changeSheetStatusGeneral([sheetInfo], 'approve');
    }

    function publish() {
        CustomFunctions.changeSheetStatusGeneral([sheetInfo], 'publish');
    }

    function unpublish() {
        CustomFunctions.changeSheetStatusGeneral([sheetInfo], 'unpublish');
    }

    function release() {
        CustomFunctions.changeSheetStatusGeneral([sheetInfo], 'release');
    }

    function viewAnalysis() {
        const scheme = apiResponse?.scheme;
        const gradesForProgram = valuesStore.getValuesBy('grade', 'edu_level', sheetInfo.grading);
        
        let examScheme = [];
        let assScheme = [];
        scheme.forEach(v => {
            if (v.label === 'Exam') {
                examScheme.push(v.custom_id);
            } else {
                assScheme.push(v.custom_id);
            }
        });

        const marks = valuesStore.getValue('marks');
        const totalRecords = marks.length;

        const grades = utils.groupBy(marks, 'grade');

        let chartLabels = [];
        let chartData = [];
        let percent = [];

        for (let i = 0; i < gradesForProgram.length; i++) {
            const grade = gradesForProgram[i]?.grade;
            chartLabels.push(grade);
            const count = grades[grade]?.length;
            const p = ((count / totalRecords) * 100)?.toFixed(2);
            chartData.push(count ? count : 0);
            percent.push(!isNaN(p) ? p : 0);
        }



        let allScores = [];
        marks.forEach(student => {
            let studentExam = 0;
            let studentAssessment = 0;
            examScheme.forEach(v => {
                if (student[v]) {
                    studentExam += parseFloat(student[v] || 0);
                }
            });
            assScheme.forEach(v => {
                if (student[v]) {
                    studentAssessment += parseFloat(student[v] || 0);
                }
            });
            allScores.push({ assessment: studentAssessment, exams: studentExam, final: (studentAssessment + studentExam), sex: student?.sex });
        });
        const sex = utils.groupBy(allScores, 'sex');

        const avgAssessment = findAvg(sumArray(allScores, 'assessment'), totalRecords);
        const maxAssessment = findMax(allScores, 'assessment');
        const minAssessment = findMin(allScores, 'assessment');

        const avgExams = findAvg(sumArray(allScores, 'exams'), totalRecords);
        const maxExams = findMax(allScores, 'exams');
        const minExams = findMin(allScores, 'exams');

        const avgFinal = findAvg(sumArray(allScores, 'final'), totalRecords);
        const maxFinal = findMax(allScores, 'final');
        const minFinal = findMin(allScores, 'final');

        const maleAvg = findAvg(sumArray(sex?.Male, 'final'), sex?.Male?.length);
        const maleMax = findMax(sex?.Male, 'final');
        const maleMin = findMin(sex?.Male, 'final');

        const femaleAvg = findAvg(sumArray(sex?.Female, 'final'), sex?.Female?.length);
        const femaleMax = findMax(sex?.Female, 'final');
        const femaleMin = findMin(sex?.Female, 'final');


        const data = {
            labels: chartLabels,
            datasets: [
                {
                    label: 'Grade distribution chart',
                    data: chartData,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ],
        };
        barchart.setData(data);        
        const theData = utils.getTable(undefined, [[<label className='fw-bold'>Grade</label>, ...chartLabels], [<label className='fw-bold'>Freq</label>, ...chartData], [<label className='fw-bold'>%</label>, ...percent]], 'table table-sm table-striped', 'headerclass', 'trClass', 'tdClass');
        setAnalysisDataTable(theData);

        const theStat = utils.getTable(
            ['', <label>Assessment</label>, <label>Examination</label>, <label>Final Score</label>, <label>Male</label>, <label>Female</label>],
            [
                [<label className='fw-bold'>Number</label>, assScheme.length, examScheme.length, 'N/A', sex?.Male?.length, sex?.Female?.length],
                [<label className='fw-bold'>Average Score</label>, avgAssessment, avgExams, avgFinal, maleAvg, femaleAvg],
                [<label className='fw-bold'>Maximum Score</label>, maxAssessment, maxExams, maxFinal, maleMax, femaleMax],
                [<label className='fw-bold'>Minimum Score</label>, minAssessment, minExams, minFinal, maleMin, femaleMin],
            ],
            'table table-sm table-striped', 'headerclass', 'trClass', 'tdClass');
        setAnalysisStatTable(theStat);
        setOpenAnalysisModal(true);
        barchart.setPlugins([barchart.allPlugins.ChartDataLabels]);
    }

    function sumArray(array, numberKey) {
        return array?.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue[numberKey] || 0), 0);
    }

    function findAvg(summation, totalCount) {
        const ans = (summation / totalCount).toFixed(2);
        return !isNaN(ans) ? ans : 0;
    }

    function findMax(array, numberKey) {
        return array?.sort((a, b) => b[numberKey] - a[numberKey])[0][numberKey];//order descending and choose the first item        
    }

    function findMin(array, numberKey) {
        return array?.sort((a, b) => a[numberKey] - b[numberKey])[0][numberKey];//order ascending and choose the first item
    }

    function tableDetails() {
        let all = apiResponse?.newSimilarStudentsList?.length;
        let reg = apiResponse?.list?.length;
        //for some reason, some students appear on the registration list for more than once due to program change and other change
        //those registrations are not needed
        if (reg > all) {//to take care of duplication of student in registration list. 
            reg = all;
        }
        return (
            <div>
                <Tooltip title='Go back'>
                    <Button onClick={() => { navigate('../course_mgt_general') }} className='me-2 btn-outline-primary'><i style={{ cursor: 'pointer' }} className='fas fa-arrow-left' /></Button>
                </Tooltip>
                <label className='fw-bold text-primary me-2'>Total Count: {all}</label>|
                <label className='fw-bold text-primary me-2 ms-2'>Registered: {reg}</label> |
                <label className='fw-bold text-primary ms-2'>Unregistered: {all - reg}</label>
            </div>
        );
    }


    return (
        <>
            <div className='containerx'>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <div className="p-2 stylish-color text-white rounded fw-bold">
                            <div className="row row-cols-3">
                                <div className="col">
                                    Program: {sheetInfo?.program}<br />
                                    Course Code: {sheetInfo?.course_code}<br />
                                    Course Name: {sheetInfo?.course_name}<br />
                                </div>
                                <div className="col">
                                    Academic Year: {sheetInfo?.acad_year}<br />
                                    Semester: {sheetInfo?.semester}<br />
                                    Session: {sheetInfo?.session}
                                </div>
                                <div className="col">
                                    Lecturer: {sheetInfo?.name}<br />
                                    Group Range :{sheetInfo?.group_from}-{sheetInfo?.group_to}<br />
                                    Submitted: {sheetInfo?.submitted ? 'Yes' : 'No'}
                                </div>
                            </div>

                            <Space className='mt-3' wrap>
                                {btnPermissions?.show_save_mark_button && <Button onClick={e => onSave()}><i className='fas fa-save me-2' /> Save</Button>}
                                {btnPermissions?.show_submit_mark_button && <Button onClick={e => onSubmit()}><i className='fas fa-paper-plane me-2' /> Submit</Button>}
                                {btnPermissions?.show_approve_button && <Button onClick={e => approve()}><i className='far fa-check-circle me-2' /> Approve</Button>}
                                {btnPermissions?.show_release_sheet_button && <Button onClick={e => release()}><i className='fas fa-unlock me-2' /> Release</Button>}
                                {btnPermissions?.show_publish_button && <Button onClick={e => publish()}><i className='fas fa-check-double  me-2' /> Publish</Button>}
                                {btnPermissions?.show_unpublish_button && <Button onClick={e => unpublish()}><i className='far fa-times-circle me-2' /> Unpublish </Button>}
                                {btnPermissions?.show_download_scoresheet_list && <Button onClick={e => onDownloadList()}><i className='fas fa-download me-2' /> Download List</Button>}
                                {btnPermissions?.show_download_scoresheet_template && <Button onClick={e => onDownloadSheet()}><i className='fas fa-download me-2' /> Download Template</Button>}
                                {btnPermissions?.show_upload_scoresheet_template && <Button onClick={e => onUploadExcel()}><i className='fas fa-upload me-2' /> Upload Template</Button>}
                                {btnPermissions?.show_upload_scoresheet_template && <Button onClick={e => onUploadExcelMap()}><i className='fas fa-upload me-2' /> Upload Template & Map</Button>}
                                {btnPermissions?.show_save_mark_button && <Button onClick={viewAnalysis} icon={<i className='fas fa-chart-bar me-1' />}>Analysis</Button>}
                                {btnPermissions?.show_sheet_print_button && <Button onClick={printResults} icon={<i className='fas fa-print me-1' />}>Print</Button>}
                            </Space>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        {table.tableWithHeaderFooter(tableDetails, tableDetails)}
                        {upload.uploader('fileUpload', '', 'upload', ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'], 'text', 'd-none', uploadRef)}
                        {uploadMap.uploader('fileUpload', '', 'uploadMap', ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'], 'text', 'd-none', uploadMapRef)}
                    </div>
                </div>
            </div>

            {dynamicForm.formModal(null, null)}

            <Modal width={1000} open={openAnalysisModal} footer={null} onCancel={e => setOpenAnalysisModal(false)}>
                <ReactToPrint
                    trigger={() => <Button size='large' className='btn-info border-0 ms-2'> <i className='fas fa-print me-2' />Print out</Button>}
                    content={() => printableAnalysis.current}
                />
                <div ref={printableAnalysis} className="container p-3">
                    <Space direction='vertical' className='d-flexx fw-bold mb-3'>
                        <label className='h4'>ASSESSMENT STATISTICS REPORT</label>
                        <label>Course: {sheetInfo?.course_code}/{sheetInfo?.course_name}/{sheetInfo?.program}/{sheetInfo?.session}</label>
                        <label>Semester: {sheetInfo?.semester}</label>
                        <label>Academic Year: {sheetInfo?.acad_year}</label>
                        <label>Lecturer: {sheetInfo?.name}</label>
                    </Space>

                    <div className='row'>
                        <div className='mb-2 col-md-8'>
                            <label className='h5'>DATA</label>
                            {analysisDataTable}
                        </div>

                        <div className='col-md-8 mb-2'>
                            <label className='h5' >CHART</label>
                            {barchart.BarChart()}
                        </div>

                        <div className='col-md-12'>
                            <label className='h5'>STATISTICS</label>
                            {analysisStatTable}
                        </div>
                    </div>
                </div>
                <ReactToPrint
                    trigger={() => <Button size='large' className='btn-info border-0 ms-2'> <i className='fas fa-print me-2' />Print out</Button>}
                    content={() => printableAnalysis.current}
                />
            </Modal>
            {studentDetailsDrawer.drawerJSX(1021)}
            {resultPrintDrawer.drawerJSX(1021)}
            {modal.modalJSX(uploadMapModalOk,
                <>
                    <div className='col-md-12'>
                        <label>Select the unique column (index number) on your excel file</label>
                        <label className='text-danger'> Unique column detected: {uniqueColumnFromExcel}</label>
                        <Select
                            className='mb-2'
                            key={`excel_uniq`}
                            placeholder="Select unique column on the excel sheet"
                            value={uniqueColumnFromExcel}
                            onChange={e => setUniqueColumnFromExcel(e)}
                            style={{
                                width: '100%',
                            }}
                            options={uploadedExcelSheetHeadings}
                        />
                    </div>
                    {uploadMapJSX}
                </>)}
        </>
    );
}

export default memo(MarkEntry);